<template>
	<div class="layout tim-millionaire-challed tim-millionaire-challenge">
		<div
			class="tim-section-default tim-section-light tim-section-center tim-challenge-intro-section"
		>
			<div class="inner">
				<div class="width-90-center">
					<header class="tim-section-header">
						<h2 class="title border tim-upprcase-text">
							I’m looking to create a world class stock trader in
							<strong>{{ geo_ip_city ? geo_ip_city : "" }}</strong>
							, my only question is,

							<strong><u>will it be you?</u></strong>
						</h2>
						<!--<h5 class="subhl">... what it involves is making short-term trades based on breaking news!</h5> -->
					</header>
				</div>

				<div class="row">
					<div class="col-12 col-lg-6">
						<div
							class="tim-video-decorator"
							style="padding-bottom: 0"
						>
							<div class="tim-video-decorator-inside">
								<div
									class="wistia_responsive_padding"
									style="padding: 56.25% 0 0 0; position: relative"
								>
									<div
										class="wistia_responsive_wrapper"
										style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
									>
										<iframe
											src="https://fast.wistia.net/embed/iframe/130uwad137?seo=false&videoFoam=true"
											title="Millionaire Publishing - Challenge 17 - Pitch Update Free - 16-9 - v1 (1) Video"
											allow="autoplay; fullscreen"
											allowtransparency="true"
											frameborder="0"
											scrolling="no"
											class="wistia_embed"
											name="wistia_embed"
											msallowfullscreen
											width="100%"
											height="100%"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-lg-6">
						<div
							class="tim-section-default tim-section-light tim-section-center tim-section-intro-newsletter"
							style="padding-top: 0; margin-top: -20px; padding-bottom: 0"
						>
							<div class="inner">
								<h3 class="title">
									Enter your email to apply for my "Trading Challenge" now
								</h3>

								<div class="tim-newsletter-box">
									<form
										id="infusion"
										accept-charset="UTF-8"
										:action="`${is_challenge_form}`"
										method="post"
										class="infusion-form"
									>
										<input
											name="inf_form_xid"
											type="hidden"
											value="730d5474de777a8d553742c8133114e7"
										/>
										<input
											name="inf_form_name"
											type="hidden"
											value="First Step Optin - MAP"
										/>
										<input
											v-if="router.query.utm_campaign"
											name="utm_campaign"
											type="hidden"
											:value="router.query.utm_campaign"
										/>
										<input
											v-if="router.query.utm_medium"
											name="utm_medium"
											type="hidden"
											:value="router.query.utm_medium"
										/>
										<input
											v-if="router.query.utm_source"
											name="utm_source"
											type="hidden"
											:value="router.query.utm_source"
										/>
										<input
											name="infusionsoft_version"
											type="hidden"
											value="1.58.0.62"
										/>
										<input
											name="inf_custom_mediasource"
											type="hidden"
											value="null"
										/>
										<input
											name="inf_custom_MCLPID"
											type="hidden"
											value="mclp-c-9-20"
										/>
										<input
											id="inf_field_FirstName"
											class="forminput w-input"
											data-name="First Name"
											maxlength="256"
											name="inf_field_FirstName"
											placeholder="Enter your first name"
											required="required"
											type="hidden"
											value=""
										/>
										<input
											id="inf_field_Email"
											class="forminput w-input tim-newsletter-input"
											data-name="Email"
											maxlength="256"
											name="inf_field_Email"
											placeholder="Enter your email address"
											required="required"
											type="email"
											value=""
										/>

										<div class="row">
											<div class="col-sm-12">
												<input
													class="formsubmit btn btn-shadow btn-large btn-call-strong"
													data-wait="Please wait..."
													type="submit"
													value="Apply for the trading challenge"
												/>
											</div>
										</div>
									</form>
									<div class="tim-disclaimer width-60-center">
										<a
											href="/terms-of-service/"
											target="_blank"
										>
											Disclaimer
										</a>
										&nbsp;&nbsp; -&nbsp;&nbsp;
										<a
											href="/privacy-policy/"
											target="_blank"
										>
											Privacy Policy
										</a>
									</div>
									<!-- <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div class="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form</div>
                  </div> -->
								</div>
								<p class="terms">
									*Results are not typical and will vary from person to person. Making
									money trading stocks takes time, dedication, and hard work. There are
									inherent risks involved with investing in the stock market, including
									the loss of your investment. Past performance in the market is not
									indicative of future results. Any investment is at your own risk.
									<a href="/terms-of-service/">See Terms of Service here</a>
									.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import axios from "axios";

const router = useRoute();

definePageMeta({
	layout: "challenge"
});

const { is_challenge_form, current_hostname } = useRuntimeConfig().public;

const geo_ip_city = ref(null);
useHead(() => ({
	title: "Millionaire Challenge - Timothy Sykes",
	meta: [
		{ property: "og:site_name", content: "Tim Sykes" },
		{ property: "og:title", content: "Tim Sykes Millionaire Challenge" },
		{
			property: "og:url",
			content: "https://www.timothysykes.com/millionaire-challenge/"
		},
		{
			property: "og:description",
			content:
				"I became a self-made millionaire by the age of 21, trading thousands of penny stocks. You may have heard the story on CNN: I turned $12,415 into a cool $1.65 Million over a couple years (I've made millions more since then). Read more at timothysykes.com/about"
		},
		{
			property: "og:image",
			content: `${current_hostname}images/study_01.jpg`
		},
		{ name: "twitter:creator", content: "@timothysykes" },
		{
			property: "twitter:image",
			content: `${current_hostname}images/study_01.jpg`
		},
		{
			property: "twitter:description",
			content:
				"I became a self-made millionaire by the age of 21, trading thousands of penny stocks. You may have heard the story on CNN: I turned $12,415 into a cool $1.65 Million over a couple years (I've made millions more since then). Read more at timothysykes.com/about"
		},
		{ property: "og:locale", content: "en_US" },
		{ property: "og:type", content: "article" },
		{ property: "og:image:width", content: "1000" },
		{ property: "og:image:height", content: "721" },
		{ property: "og:image:type", content: "image/jpeg" }
	],
	script: [
		{
			src: "https://fast.wistia.net/assets/external/E-v1.js",
			type: "application/javascript",
			async: true
		}
	],
	link: [{ rel: "canonical", href: `${current_hostname}millionaire-challenge/` }]
}));

onMounted(async () => {
	if (process.client) {
		const { data } = await axios.get(
			"https://www.timsykeswatchlist.com/challenge/geo-rest.php"
		);

		geo_ip_city.value = data;

		if (document.querySelector(".about-contact-links.ctnr")) {
			document.querySelector(".about-contact-links.ctnr").style.display = "none";
		}

		const ctas = document.querySelectorAll(".convertflow-cta");
		if (ctas && ctas.length > 0) {
			[...ctas].forEach((cta) => {
				cta.style.display = "none";
			});
		}

		const observer = new MutationObserver(function (mutations_list) {
			mutations_list.forEach(function (mutation) {
				if (mutation.type === "childList") {
					mutation.addedNodes.forEach(function (added_node) {
						if (added_node.className === "convertflow-cta") {
							added_node.remove();
						}
					});
				}
			});
		});

		observer.observe(document, {
			subtree: true,
			childList: true,
			attributes: true
		});
	}
});
</script>

<style lang="scss" scoped>
@charset "UTF-8";

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
.container:after,
.container:before {
	content: " ";
	display: table;
}
.container:after {
	clear: both;
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
}
.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
.container-fluid:after,
.container-fluid:before {
	content: " ";
	display: table;
}
.container-fluid:after {
	clear: both;
}
.row {
	margin-left: -15px;
	margin-right: -15px;
}
.row:after,
.row:before {
	content: " ";
	display: table;
}
.row:after {
	clear: both;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	float: left;
}
.col-xs-1 {
	width: 8.33333%;
}
.col-xs-2 {
	width: 16.66667%;
}
.col-xs-3 {
	width: 25%;
}
.col-xs-4 {
	width: 33.33333%;
}
.col-xs-5 {
	width: 41.66667%;
}
.col-xs-6 {
	width: 50%;
}
.col-xs-7 {
	width: 58.33333%;
}
.col-xs-8 {
	width: 66.66667%;
}
.col-xs-9 {
	width: 75%;
}
.col-xs-10 {
	width: 83.33333%;
}
.col-xs-11 {
	width: 91.66667%;
}
.col-xs-12 {
	width: 100%;
}
.col-xs-pull-0 {
	right: auto;
}
.col-xs-pull-1 {
	right: 8.33333%;
}
.col-xs-pull-2 {
	right: 16.66667%;
}
.col-xs-pull-3 {
	right: 25%;
}
.col-xs-pull-4 {
	right: 33.33333%;
}
.col-xs-pull-5 {
	right: 41.66667%;
}
.col-xs-pull-6 {
	right: 50%;
}
.col-xs-pull-7 {
	right: 58.33333%;
}
.col-xs-pull-8 {
	right: 66.66667%;
}
.col-xs-pull-9 {
	right: 75%;
}
.col-xs-pull-10 {
	right: 83.33333%;
}
.col-xs-pull-11 {
	right: 91.66667%;
}
.col-xs-pull-12 {
	right: 100%;
}
.col-xs-push-0 {
	left: auto;
}
.col-xs-push-1 {
	left: 8.33333%;
}
.col-xs-push-2 {
	left: 16.66667%;
}
.col-xs-push-3 {
	left: 25%;
}
.col-xs-push-4 {
	left: 33.33333%;
}
.col-xs-push-5 {
	left: 41.66667%;
}
.col-xs-push-6 {
	left: 50%;
}
.col-xs-push-7 {
	left: 58.33333%;
}
.col-xs-push-8 {
	left: 66.66667%;
}
.col-xs-push-9 {
	left: 75%;
}
.col-xs-push-10 {
	left: 83.33333%;
}
.col-xs-push-11 {
	left: 91.66667%;
}
.col-xs-push-12 {
	left: 100%;
}
.col-xs-offset-0 {
	margin-left: 0;
}
.col-xs-offset-1 {
	margin-left: 8.33333%;
}
.col-xs-offset-2 {
	margin-left: 16.66667%;
}
.col-xs-offset-3 {
	margin-left: 25%;
}
.col-xs-offset-4 {
	margin-left: 33.33333%;
}
.col-xs-offset-5 {
	margin-left: 41.66667%;
}
.col-xs-offset-6 {
	margin-left: 50%;
}
.col-xs-offset-7 {
	margin-left: 58.33333%;
}
.col-xs-offset-8 {
	margin-left: 66.66667%;
}
.col-xs-offset-9 {
	margin-left: 75%;
}
.col-xs-offset-10 {
	margin-left: 83.33333%;
}
.col-xs-offset-11 {
	margin-left: 91.66667%;
}
.col-xs-offset-12 {
	margin-left: 100%;
}
@media (min-width: 768px) {
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9 {
		float: left;
	}
	.col-sm-1 {
		width: 8.33333%;
	}
	.col-sm-2 {
		width: 16.66667%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-4 {
		width: 33.33333%;
	}
	.col-sm-5 {
		width: 41.66667%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-7 {
		width: 58.33333%;
	}
	.col-sm-8 {
		width: 66.66667%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-10 {
		width: 83.33333%;
	}
	.col-sm-11 {
		width: 91.66667%;
	}
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-pull-0 {
		right: auto;
	}
	.col-sm-pull-1 {
		right: 8.33333%;
	}
	.col-sm-pull-2 {
		right: 16.66667%;
	}
	.col-sm-pull-3 {
		right: 25%;
	}
	.col-sm-pull-4 {
		right: 33.33333%;
	}
	.col-sm-pull-5 {
		right: 41.66667%;
	}
	.col-sm-pull-6 {
		right: 50%;
	}
	.col-sm-pull-7 {
		right: 58.33333%;
	}
	.col-sm-pull-8 {
		right: 66.66667%;
	}
	.col-sm-pull-9 {
		right: 75%;
	}
	.col-sm-pull-10 {
		right: 83.33333%;
	}
	.col-sm-pull-11 {
		right: 91.66667%;
	}
	.col-sm-pull-12 {
		right: 100%;
	}
	.col-sm-push-0 {
		left: auto;
	}
	.col-sm-push-1 {
		left: 8.33333%;
	}
	.col-sm-push-2 {
		left: 16.66667%;
	}
	.col-sm-push-3 {
		left: 25%;
	}
	.col-sm-push-4 {
		left: 33.33333%;
	}
	.col-sm-push-5 {
		left: 41.66667%;
	}
	.col-sm-push-6 {
		left: 50%;
	}
	.col-sm-push-7 {
		left: 58.33333%;
	}
	.col-sm-push-8 {
		left: 66.66667%;
	}
	.col-sm-push-9 {
		left: 75%;
	}
	.col-sm-push-10 {
		left: 83.33333%;
	}
	.col-sm-push-11 {
		left: 91.66667%;
	}
	.col-sm-push-12 {
		left: 100%;
	}
	.col-sm-offset-0 {
		margin-left: 0;
	}
	.col-sm-offset-1 {
		margin-left: 8.33333%;
	}
	.col-sm-offset-2 {
		margin-left: 16.66667%;
	}
	.col-sm-offset-3 {
		margin-left: 25%;
	}
	.col-sm-offset-4 {
		margin-left: 33.33333%;
	}
	.col-sm-offset-5 {
		margin-left: 41.66667%;
	}
	.col-sm-offset-6 {
		margin-left: 50%;
	}
	.col-sm-offset-7 {
		margin-left: 58.33333%;
	}
	.col-sm-offset-8 {
		margin-left: 66.66667%;
	}
	.col-sm-offset-9 {
		margin-left: 75%;
	}
	.col-sm-offset-10 {
		margin-left: 83.33333%;
	}
	.col-sm-offset-11 {
		margin-left: 91.66667%;
	}
	.col-sm-offset-12 {
		margin-left: 100%;
	}
}
@media (min-width: 992px) {
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9 {
		float: left;
	}
	.col-md-1 {
		width: 8.33333%;
	}
	.col-md-2 {
		width: 16.66667%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-4 {
		width: 33.33333%;
	}
	.col-md-5 {
		width: 41.66667%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-7 {
		width: 58.33333%;
	}
	.col-md-8 {
		width: 66.66667%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-10 {
		width: 83.33333%;
	}
	.col-md-11 {
		width: 91.66667%;
	}
	.col-md-12 {
		width: 100%;
	}
	.col-md-pull-0 {
		right: auto;
	}
	.col-md-pull-1 {
		right: 8.33333%;
	}
	.col-md-pull-2 {
		right: 16.66667%;
	}
	.col-md-pull-3 {
		right: 25%;
	}
	.col-md-pull-4 {
		right: 33.33333%;
	}
	.col-md-pull-5 {
		right: 41.66667%;
	}
	.col-md-pull-6 {
		right: 50%;
	}
	.col-md-pull-7 {
		right: 58.33333%;
	}
	.col-md-pull-8 {
		right: 66.66667%;
	}
	.col-md-pull-9 {
		right: 75%;
	}
	.col-md-pull-10 {
		right: 83.33333%;
	}
	.col-md-pull-11 {
		right: 91.66667%;
	}
	.col-md-pull-12 {
		right: 100%;
	}
	.col-md-push-0 {
		left: auto;
	}
	.col-md-push-1 {
		left: 8.33333%;
	}
	.col-md-push-2 {
		left: 16.66667%;
	}
	.col-md-push-3 {
		left: 25%;
	}
	.col-md-push-4 {
		left: 33.33333%;
	}
	.col-md-push-5 {
		left: 41.66667%;
	}
	.col-md-push-6 {
		left: 50%;
	}
	.col-md-push-7 {
		left: 58.33333%;
	}
	.col-md-push-8 {
		left: 66.66667%;
	}
	.col-md-push-9 {
		left: 75%;
	}
	.col-md-push-10 {
		left: 83.33333%;
	}
	.col-md-push-11 {
		left: 91.66667%;
	}
	.col-md-push-12 {
		left: 100%;
	}
	.col-md-offset-0 {
		margin-left: 0;
	}
	.col-md-offset-1 {
		margin-left: 8.33333%;
	}
	.col-md-offset-2 {
		margin-left: 16.66667%;
	}
	.col-md-offset-3 {
		margin-left: 25%;
	}
	.col-md-offset-4 {
		margin-left: 33.33333%;
	}
	.col-md-offset-5 {
		margin-left: 41.66667%;
	}
	.col-md-offset-6 {
		margin-left: 50%;
	}
	.col-md-offset-7 {
		margin-left: 58.33333%;
	}
	.col-md-offset-8 {
		margin-left: 66.66667%;
	}
	.col-md-offset-9 {
		margin-left: 75%;
	}
	.col-md-offset-10 {
		margin-left: 83.33333%;
	}
	.col-md-offset-11 {
		margin-left: 91.66667%;
	}
	.col-md-offset-12 {
		margin-left: 100%;
	}
}
@media (min-width: 1200px) {
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9 {
		float: left;
	}
	.col-lg-1 {
		width: 8.33333%;
	}
	.col-lg-2 {
		width: 16.66667%;
	}
	.col-lg-3 {
		width: 25%;
	}
	.col-lg-4 {
		width: 33.33333%;
	}
	.col-lg-5 {
		width: 41.66667%;
	}
	.col-lg-6 {
		width: 50%;
	}
	.col-lg-7 {
		width: 58.33333%;
	}
	.col-lg-8 {
		width: 66.66667%;
	}
	.col-lg-9 {
		width: 75%;
	}
	.col-lg-10 {
		width: 83.33333%;
	}
	.col-lg-11 {
		width: 91.66667%;
	}
	.col-lg-12 {
		width: 100%;
	}
	.col-lg-pull-0 {
		right: auto;
	}
	.col-lg-pull-1 {
		right: 8.33333%;
	}
	.col-lg-pull-2 {
		right: 16.66667%;
	}
	.col-lg-pull-3 {
		right: 25%;
	}
	.col-lg-pull-4 {
		right: 33.33333%;
	}
	.col-lg-pull-5 {
		right: 41.66667%;
	}
	.col-lg-pull-6 {
		right: 50%;
	}
	.col-lg-pull-7 {
		right: 58.33333%;
	}
	.col-lg-pull-8 {
		right: 66.66667%;
	}
	.col-lg-pull-9 {
		right: 75%;
	}
	.col-lg-pull-10 {
		right: 83.33333%;
	}
	.col-lg-pull-11 {
		right: 91.66667%;
	}
	.col-lg-pull-12 {
		right: 100%;
	}
	.col-lg-push-0 {
		left: auto;
	}
	.col-lg-push-1 {
		left: 8.33333%;
	}
	.col-lg-push-2 {
		left: 16.66667%;
	}
	.col-lg-push-3 {
		left: 25%;
	}
	.col-lg-push-4 {
		left: 33.33333%;
	}
	.col-lg-push-5 {
		left: 41.66667%;
	}
	.col-lg-push-6 {
		left: 50%;
	}
	.col-lg-push-7 {
		left: 58.33333%;
	}
	.col-lg-push-8 {
		left: 66.66667%;
	}
	.col-lg-push-9 {
		left: 75%;
	}
	.col-lg-push-10 {
		left: 83.33333%;
	}
	.col-lg-push-11 {
		left: 91.66667%;
	}
	.col-lg-push-12 {
		left: 100%;
	}
	.col-lg-offset-0 {
		margin-left: 0;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%;
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%;
	}
	.col-lg-offset-3 {
		margin-left: 25%;
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%;
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%;
	}
	.col-lg-offset-6 {
		margin-left: 50%;
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%;
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%;
	}
	.col-lg-offset-9 {
		margin-left: 75%;
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%;
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%;
	}
	.col-lg-offset-12 {
		margin-left: 100%;
	}
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
	content: "";
	content: none;
}
html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}
:active,
:focus {
	outline: 0;
}
.icon:before {
	font-family: icomoon !important;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-envelope-o:before {
	content: "\e901";
}
.icon-youtube-play:before {
	content: "\e900";
}
.icon-facebook:before {
	content: "\ea90";
}
.icon-facebook2:before {
	content: "\ea91";
}
.icon-instagram:before {
	content: "\ea92";
}
.icon-twitter:before {
	content: "\ea96";
}
.icon-linkedin:before {
	content: "\eac9";
}
.icon-linkedin2:before {
	content: "\eaca";
}
html {
	-webkit-tap-highlight-color: transparent;
	background: #3fa9f5;
}
body {
	background: #fff;
	color: #292b2c;
}
a {
	text-decoration: none;
	color: #3fa9f5;
}
a:hover {
	text-decoration: underline;
}
p {
	font-family: Fira Sans;
	font-size: 16px;
	line-height: 1.6;
}
img {
	max-width: 100%;
	height: auto;
	display: block;
}
img.tim-img-block {
	display: block !important;
}
.inner {
	width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
	max-width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.bg-g {
	background: #f5f5f5;
}
.border {
	position: relative;
}
.border:after {
	content: "";
	display: block;
	width: 60px;
	height: 2px;
	background: #3fa9f5;
	position: absolute;
	bottom: 0;
	left: 41%;
}
@media (min-width: 768px) {
	.border:after {
		left: 47.4%;
	}
}
.clear:after {
	content: "";
	display: table;
	clear: both;
}
@media (min-width: 768px) {
	.tim-pull-right {
		float: right !important;
	}
}
.hidden {
	display: none;
}
.tim-upprcase-text {
	text-transform: uppercase;
}
.col-100,
.col-50 {
	float: left;
}
.col-100 {
	width: 100%;
}
.col-50 {
	width: 50%;
}
@media (max-width: 767px) {
	.col-50 {
		width: 100%;
	}
}
.col-50:last-of-type {
	float: right;
}
.col-50.clear {
	clear: both;
}
.col-50.clear:after {
	display: none;
}
@media (min-width: 768px) {
	.width-90-center {
		width: 90%;
		margin: 0 auto;
	}
}
@media (min-width: 768px) {
	.width-80-center {
		width: 80%;
		margin: 0 auto;
	}
}
@media (min-width: 768px) {
	.width-70-center {
		width: 70%;
		margin: 0 auto;
	}
}
@media (min-width: 768px) {
	.width-65-center {
		width: 65%;
		margin: 0 auto;
	}
}
@media (min-width: 768px) {
	.width-60-center {
		width: 60%;
		margin: 0 auto;
	}
}
@media (min-width: 768px) {
	.width-55-center {
		width: 55%;
		margin: 0 auto;
	}
}
.tim-separate-text {
	margin: 30px 0 !important;
}
.tim-center-text {
	text-align: center !important;
}
.tim-inline-image {
	display: inline;
}
.tim-img-md-screen {
	display: none !important;
}
@media (min-width: 768px) {
	.tim-img-md-screen {
		display: block !important;
	}
}
.tim-img-small-screen {
	display: block !important;
}
@media (min-width: 768px) {
	.tim-img-small-screen {
		display: none !important;
	}
}
iframe[name="google_conversion_frame"] {
	position: absolute;
	bottom: 0;
}
body,
button,
input,
select,
textarea {
	font: 300 18px/1.666666666667em geomanist, Helvetica, Arial;
}
strong {
	font-weight: 700;
}
.center {
	text-align: center;
}
.underline {
	text-decoration: underline;
}
@media (min-width: 1200px) {
	.nl {
		display: block;
	}
}
.red {
	color: #3fa9f5 !important;
}
.black {
	color: #292b2c !important;
}
.light {
	font-weight: 300 !important;
}
.regular {
	font-weight: 400 !important;
}
.book {
	font-weight: 500 !important;
}
.medium {
	font-weight: 600 !important;
}
.bold {
	font-weight: 700 !important;
}
.italic {
	font-style: italic !important;
}
.size-12 {
	font-size: 12px;
	line-height: 1.66667;
}
.size-14 {
	font-size: 14px;
	line-height: 1.66667;
}
.size-16 {
	font-size: 16px;
	line-height: 1.66667;
}
.size-20 {
	font-size: 18px;
	line-height: 1.66667;
}
@media (min-width: 768px) {
	.size-20 {
		font-size: 20px;
		line-height: 1.8;
	}
}
.size-22 {
	font-size: 18px;
	line-height: 1.66667;
}
@media (min-width: 768px) {
	.size-22 {
		font-size: 22px;
		line-height: 1.63636;
	}
}
.size-24 {
	font-size: 18px;
	line-height: 1.66667;
}
@media (min-width: 768px) {
	.size-24 {
		font-size: 24px;
		line-height: 1.41667;
	}
}
.size-34 {
	font-size: 24px;
	line-height: 1.41667;
}
@media (min-width: 768px) {
	.size-34 {
		font-size: 34px;
		line-height: 1.17647;
	}
}
.size-36 {
	font-size: 24px;
	line-height: 1.41667;
}
@media (min-width: 768px) {
	.size-36 {
		font-size: 36px;
		line-height: 1.02778;
	}
}
.size-40 {
	font-size: 24px;
	line-height: 1.41667;
}
@media (min-width: 480px) {
	.size-40 {
		font-size: 31px;
		line-height: 1.5;
	}
}
@media (min-width: 768px) {
	.size-40 {
		font-size: 40px;
		line-height: 1.5;
	}
}
.size-48 {
	font-size: 34px;
	line-height: 1.17647;
}
@media (min-width: 480px) {
	.size-48 {
		font-size: 40px;
		line-height: 1.5;
	}
}
@media (min-width: 768px) {
	.size-48 {
		font-size: 48px;
		line-height: 1.292857143;
	}
}
.size-56 {
	font-size: 34px;
	line-height: 1.17647;
}
@media (min-width: 480px) {
	.size-56 {
		font-size: 40px;
		line-height: 1.5;
	}
}
@media (min-width: 1200px) {
	.size-56 {
		font-size: 56px;
		line-height: 1;
	}
}
.size-84 {
	font-size: 34px;
	line-height: 1.17647;
}
@media (min-width: 480px) {
	.size-84 {
		font-size: 56px;
		line-height: 1;
	}
}
@media (min-width: 1200px) {
	.size-84 {
		font-size: 84px;
		line-height: 1.11905;
	}
}
.btn,
.btn-wrap a,
.btn-wrap a:visited,
.btn:visited {
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
	letter-spacing: 0.5px;
	padding: 21px 40px 21px 40px;
	width: auto;
	border: solid 1px;
	cursor: pointer;
	border-radius: 3px;
	white-space: normal;
	background: #3fa9f5;
	color: #fff;
}
.btn-wrap a:hover,
.btn-wrap a:visited:hover,
.btn:hover,
.btn:visited:hover {
	text-decoration: none;
	color: #3fa9f5;
	background: #fff;
}
@media (min-width: 480px) {
	.btn,
	.btn-wrap a,
	.btn-wrap a:visited,
	.btn:visited {
		min-width: 140px;
	}
}
.btn small,
.btn-wrap a small,
.btn-wrap a:visited small,
.btn:visited small {
	text-transform: none;
	font-weight: 300;
}
.btn-wrap a.btn-apply,
.btn-wrap a:visited.btn-apply,
.btn.btn-apply,
.btn:visited.btn-apply {
	font-size: 18px;
	padding: 20px 40px;
}
.btn-wrap a.btn-call-strong,
.btn-wrap a:visited.btn-call-strong,
.btn.btn-call-strong,
.btn:visited.btn-call-strong {
	background: #ff4500;
	border: 1px solid #ff4500 !important;
}
.btn-wrap a.btn-call-strong:hover,
.btn-wrap a:visited.btn-call-strong:hover,
.btn.btn-call-strong:hover,
.btn:visited.btn-call-strong:hover {
	background: #fff;
	color: #ff4500;
}
@media (min-width: 768px) {
	.btn-wrap a.btn-large,
	.btn-wrap a:visited.btn-large,
	.btn.btn-large,
	.btn:visited.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
}
.btn-s,
.btn-wrap-s a,
.site-menu .menu-item.btn-wrap-s a {
	padding-top: 10px;
	padding-bottom: 10px;
}
.btn-rww {
	background-color: #fff;
	border-color: #fff;
	color: #3fa9f5;
}
.btn-rww:hover {
	color: #292b2c;
}
.btn-shadow {
	border-color: #3fa9f5 !important;
	-webkit-box-shadow: 0 9px 50px 0 rgba(54, 57, 73, 0.2);
	box-shadow: 0 9px 50px 0 rgba(54, 57, 73, 0.2);
	margin-bottom: 50px;
}
.btn-shadow-blue {
	border-color: #3fa9f5 !important;
	-webkit-box-shadow: 0 10px 40px 0 rgba(48, 192, 236, 0.2);
	box-shadow: 0 10px 40px 0 rgba(48, 192, 236, 0.2);
	border-radius: 2px;
}
.btn-rwr {
	background-color: #fff;
	border-color: #3fa9f5;
	color: #3fa9f5;
}
.btn-rwr:hover {
	background-color: #3fa9f5;
	border-color: #3fa9f5;
	color: #fff;
}
.btn-wrap-wrw a,
.btn-wrw {
	background-color: #3fa9f5;
	border-color: #fff;
	color: #fff;
}
.btn-wrap-wrw a:hover,
.btn-wrw:hover {
	background-color: #fff;
	border-color: #fff;
	color: #292b2c !important;
}
.btn-wrr {
	background-color: #3fa9f5;
	border-color: #3fa9f5;
	color: #fff;
}
.btn-wrr:hover {
	background-color: #fff;
	border-color: #3fa9f5;
	color: #3fa9f5;
}
.btn-group-lg {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.btn-group-lg li {
	margin: 2px;
	float: left;
}
@media (min-width: 768px) {
	.btn-group-lg li {
		margin: 0;
	}
}
.btn-group-lg li .btn,
.btn-group-lg li.btn {
	display: block;
	background: #fff;
	color: #aeb0b3;
	padding: 16px 15px;
	letter-spacing: 1.9px;
	border: none !important;
	margin-right: 1px;
}
@media (min-width: 768px) {
	.btn-group-lg li .btn,
	.btn-group-lg li.btn {
		min-width: 195px;
		border-right: none;
		border-radius: 0;
	}
}
.btn-group-lg li .btn.active-tab,
.btn-group-lg li .btn:hover,
.btn-group-lg li.btn.active-tab,
.btn-group-lg li.btn:hover {
	background: #3fa9f5;
	color: #fff;
	border-color: #3fa9f5;
	border-radius: 2px;
}
@media (min-width: 768px) {
	.btn-group-lg li:first-child .btn {
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}
}
.btn-group-lg li:last-child .btn {
	border-right: none;
}
.btn-group-lg li:last-child .btn:hover {
	border-right-color: #3fa9f5;
}
input,
select,
textarea {
	font-size: 15px;
	color: #292b2c;
	padding: 15px 0 15px 0;
	background: 0 0;
	border: 0;
	border-bottom: 2px solid #c9caca;
	margin-bottom: 15px;
	font-family: Fira Sans;
	font-weight: 400;
}
input:focus,
select:focus,
textarea:focus {
	outline: 0;
}
input[type="submit"] {
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
	letter-spacing: 0.5px;
	padding: 21px 40px 21px 40px;
	width: auto;
	border: solid 1px;
	cursor: pointer;
	border-radius: 3px;
	white-space: normal;
	background: #3fa9f5;
	color: #fff;
}
input[type="submit"]:hover {
	text-decoration: none;
	color: #3fa9f5;
	background: #fff;
}
@media (min-width: 480px) {
	input[type="submit"] {
		min-width: 140px;
	}
}
textarea {
	display: block;
	padding-top: 25px;
	padding-bottom: 25px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #a4a6aa;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #a4a6aa;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: #a4a6aa;
}
input::placeholder,
textarea::placeholder {
	color: #292b2c;
}
.checkbox-wrap {
	width: 18px;
	height: 18px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}
.checkbox-wrap input {
	display: none;
}
.checkbox-wrap input:checked + span:after {
	opacity: 1;
}
.checkbox-wrap span {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	background: #fff;
	border: solid #e5e5e6 1px;
}
.checkbox-wrap span:after {
	content: "";
	display: block;
	width: 9px;
	height: 5px;
	position: absolute;
	top: 5px;
	left: 4px;
	border: solid 2px #3fa9f5;
	border-top: none;
	border-right: none;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	opacity: 0;
}
.nice-select {
	position: relative;
	width: 100%;
	height: 62px;
	margin: 0;
	padding: 0;
	background: 0 0;
	border: 0;
	font-size: 15px;
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}
.nice-select:hover {
	outline: 0;
}
.nice-select span {
	position: absolute;
	left: 0;
	display: block;
	width: 60%;
	padding: 15px 0 0 30px;
	font-size: 15px;
	font-weight: 400;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.nice-select:after {
	content: "";
	display: block;
	pointer-events: none;
	position: absolute;
	right: 33px;
	top: 25px;
	-webkit-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 7px;
	height: 7px;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
}
.nice-select.open {
	z-index: 999;
}
.nice-select.open:after {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.nice-select .list {
	position: absolute;
	top: 60px;
	right: 0;
	left: 0;
	display: block;
	padding: 0;
	width: auto;
	margin: 0;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: none;
	transform: none;
	-webkit-transform-origin: none;
	transform-origin: none;
	border: 0;
	border-radius: 2px;
	background-color: #fff;
	-webkit-box-shadow: 0 15px 30px 0 rgba(54, 57, 73, 0.09);
	box-shadow: 0 15px 30px 0 rgba(54, 57, 73, 0.09);
	opacity: 0;
	pointer-events: none;
	z-index: 9;
	overflow: hidden;
}
.nice-select .list .option {
	cursor: pointer;
	line-height: 40px;
	list-style: none;
	min-height: 61px;
	outline: 0;
	text-align: left;
	margin: 0;
	padding: 21px 31px 0;
	background-color: transparent;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.66667;
	color: #292b2c;
	text-align: left;
}
.nice-select .list .option.disabled {
	display: none;
}
.nice-select .list .option.selected,
.nice-select .list .option:hover {
	background-color: #f6f8f9;
	color: #3fa9f5;
}
.nice-select.disabled-first .list .option:first-child {
	display: none;
}
.nice-select.open {
	border-radius: 2px 0 0 0;
}
.nice-select.open .list {
	opacity: 1;
	height: 175px;
	border-radius: 0 2px 2px 2px;
	pointer-events: auto;
}
.nice-select.height-183 .list {
	height: 183px;
}
.nice-select.height-195 .list {
	height: 195px;
}
.nice-select.height-305 .list {
	height: 305px;
}
.nice-select.height-366 .list {
	height: 366px;
}
.nice-select.height-424 .list {
	height: 424px;
}
.nice-select .optiscroll-vtrack {
	width: 4px;
}
.nice-select.disabled {
	pointer-events: none;
}
.nice-select.disabled .list .option {
	background-color: transparent;
	color: #999;
	cursor: default;
}
.nice-select.wide {
	background-color: transparent;
}
@media (min-width: 768px) {
	.nice-select.wide {
		height: 75px;
	}
}
.nice-select.wide:after {
	right: 42px;
	top: 32px;
}
@media (max-width: 767px) {
	.nice-select.wide:after {
		right: 3px;
	}
}
.nice-select.wide span {
	width: 100%;
	color: rgba(255, 255, 255, 0.3);
}
@media (min-width: 768px) {
	.nice-select.wide span {
		padding: 17px 25px 0 40px;
		font-size: 34px;
		line-height: 38px;
	}
}
@media (max-width: 767px) {
	.nice-select.wide span {
		height: 60px;
		padding-left: 0;
	}
}
.nice-select.wide .list {
	top: 75px;
	right: 0;
	left: 0;
}
@media (max-width: 767px) {
	.nice-select.wide .list {
		top: 60px;
	}
}
.nice-select.wide .list .option {
	padding: 20px 40px;
}
select.nice-select {
	height: 60px;
	padding: 0 0 0 25px;
	font-size: 15px;
	color: rgba(255, 255, 255, 0.3);
}
select.nice-select.wide {
	height: 75px;
	background-color: transparent;
	padding: 0 25px 0 40px;
	font-size: 34px;
	color: #000;
}
.transparent select.nice-select {
	border-bottom: 1px solid #fff;
}
.transparent .nice-select:before {
	content: "";
	position: absolute;
	right: 0;
	bottom: -2px;
	left: 0;
	height: 1px;
	background-color: #fff;
}
@media (max-width: 767px) {
	.transparent .nice-select:after {
		right: 3px;
	}
}
.transparent .nice-select.wide:before {
	bottom: 0;
}
.transparent .nice-select span {
	color: #fff;
}
@media (max-width: 767px) {
	.transparent .nice-select span {
		padding-left: 0;
	}
}
.transparent .nice-select.wide span {
	color: rgba(255, 255, 255, 0.3);
}
.transparent .nice-select.open {
	background-color: transparent;
}
.transparent input {
	background-color: transparent;
	border-bottom: 1px solid #fff;
	font-size: 15px;
	color: #fff;
	caret-color: #fff;
}
.transparent input::-webkit-input-placeholder {
	color: #fff;
}
.transparent input::-moz-placeholder {
	color: #fff;
}
.transparent input::-ms-input-placeholder {
	color: #fff;
}
.transparent input::placeholder {
	color: #fff;
}
.transparent input:focus::-webkit-input-placeholder {
	color: #fff;
}
.transparent input:focus::-moz-placeholder {
	color: #fff;
}
.transparent input:focus::-ms-input-placeholder {
	color: #fff;
}
.transparent input:focus::placeholder {
	color: #fff;
}
.transparent input[type="submit"] {
	background-color: #fff;
	border: 1px solid #fff;
	font-size: 12px;
	font-weight: 700;
	color: #3fa9f5;
	text-transform: uppercase;
}
@media (max-width: 767px) {
	.transparent input[type="submit"] {
		width: 100%;
	}
}
.transparent input[type="submit"]:hover {
	color: #292b2c;
}
.transparent .wpcf7-not-valid-tip {
	display: block;
	padding: 10px 30px;
	font-size: 15px;
	line-height: 1.66667;
	color: #fff;
	text-align: left;
}
@media (max-width: 767px) {
	.transparent .wpcf7-not-valid-tip {
		width: 100%;
		padding: 10px 0;
	}
}
.transparent .wpcf7-response-output {
	padding: 0 40px;
	color: #fff;
}
@media (max-width: 767px) {
	.transparent .wpcf7-response-output {
		padding: 10px;
	}
}
.transparent .wpcf7-validation-errors {
	color: #fff;
}
.transparent.with-gray select.nice-select {
	border-bottom: 1px solid #43464b;
}
.transparent.with-gray .nice-select:before {
	background-color: #43464b;
}
.transparent.with-gray .nice-select:after {
	border-color: #4b4e53;
}
@media (max-width: 767px) {
	.transparent.with-gray .nice-select:after {
		right: 3px;
	}
}
.transparent.with-gray .nice-select .list {
	margin-top: 2px;
}
.transparent.with-gray .nice-select.wide:before {
	bottom: 0;
}
.transparent.with-gray .nice-select span {
	color: #707378;
}
@media (max-width: 767px) {
	.transparent.with-gray .nice-select span {
		padding-left: 0;
	}
}
.transparent.with-gray .nice-select.wide span {
	color: #707378;
}
.transparent.with-gray .nice-select.open {
	background-color: transparent;
}
.transparent.with-gray input {
	border-bottom: 1px solid #43464b;
	color: #707378;
	caret-color: #43464b;
}
.transparent.with-gray input::-webkit-input-placeholder {
	color: #707378;
}
.transparent.with-gray input::-moz-placeholder {
	color: #707378;
}
.transparent.with-gray input::-ms-input-placeholder {
	color: #707378;
}
.transparent.with-gray input::placeholder {
	color: #707378;
}
.transparent.with-gray input:focus::-webkit-input-placeholder {
	color: rgba(112, 115, 120, 0.3);
}
.transparent.with-gray input:focus::-moz-placeholder {
	color: rgba(112, 115, 120, 0.3);
}
.transparent.with-gray input:focus::-ms-input-placeholder {
	color: rgba(112, 115, 120, 0.3);
}
.transparent.with-gray input:focus::placeholder {
	color: rgba(112, 115, 120, 0.3);
}
.transparent.with-gray input[type="submit"] {
	background-color: #fff;
	border: 1px solid #3fa9f5;
	color: #3fa9f5;
}
@media (max-width: 767px) {
	.transparent.with-gray input[type="submit"] {
		width: 100%;
	}
}
.transparent.with-gray input[type="submit"]:hover {
	background-color: #3fa9f5;
	color: #fff;
}
.transparent.with-gray .wpcf7-not-valid-tip,
.transparent.with-gray .wpcf7-response-output,
.transparent.with-gray .wpcf7-validation-errors {
	color: #3fa9f5;
}
.gray .nice-select {
	background-color: rgba(255, 255, 255, 0.2);
}
.gray .nice-select span {
	color: #fff;
}
.gray .nice-select.open {
	background-color: #f7f9fa;
}
.gray .nice-select.open span {
	color: #42454b;
}
.gray .nice-select.open:after {
	border-color: #42454b;
}
.gray input {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff;
	caret-color: #fff;
}
.gray input:focus::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.6);
}
.gray input:focus::-moz-placeholder {
	color: rgba(255, 255, 255, 0.6);
}
.gray input:focus::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.6);
}
.gray input:focus::placeholder {
	color: rgba(255, 255, 255, 0.6);
}
.gray input[type="submit"] {
	background-color: #fff;
	border: 1px solid #fff;
	font-size: 12px;
	font-weight: 700;
	color: #3fa9f5;
	text-transform: uppercase;
}
.gray input[type="submit"]:hover {
	color: #292b2c;
}
.comment-form,
.form {
	margin: auto;
}
.comment-form input,
.form input {
	width: 100%;
	height: 60px;
}
@media (max-width: 767px) {
	.comment-form input,
	.form input {
		padding: 0 30px;
	}
}
.comment-form textarea,
.form textarea {
	width: 100%;
	height: 136px;
}
.comment-form .form-submit,
.form .form-submit {
	width: auto;
	letter-spacing: 0.16em;
}
@media (max-width: 767px) {
	.comment-form .form-submit,
	.form .form-submit {
		width: 100%;
	}
}
.comment-form.two-columns form,
.form.two-columns form {
	display: block;
	margin-right: -40px;
	margin-left: -40px;
}
@media (max-width: 1279px) {
	.comment-form.two-columns form,
	.form.two-columns form {
		margin-right: -10px;
		margin-left: -10px;
	}
}
.comment-form.two-columns [class*="col-"],
.form.two-columns [class*="col-"] {
	padding: 0 40px;
}
@media (max-width: 1279px) {
	.comment-form.two-columns [class*="col-"],
	.form.two-columns [class*="col-"] {
		padding: 0 10px;
	}
}
.comment-form.two-columns fieldset,
.form.two-columns fieldset {
	margin-bottom: 45px;
	text-align: right;
}
@media (max-width: 767px) {
	.comment-form.two-columns fieldset,
	.form.two-columns fieldset {
		margin-bottom: 15px;
	}
}
.comment-form.two-columns input,
.form.two-columns input {
	height: 62px;
}
@media (max-width: 767px) {
	.comment-form.two-columns input,
	.form.two-columns input {
		padding: 0;
	}
}
.comment-form.two-columns input[type="submit"],
.form.two-columns input[type="submit"] {
	position: relative;
	display: inline-block;
	min-width: 191px;
	height: 60px;
}
@media (max-width: 767px) {
	.comment-form.two-columns input[type="submit"],
	.form.two-columns input[type="submit"] {
		top: 15px;
		width: 100%;
	}
}
.form-lt {
	padding-top: 143px;
	padding-bottom: 102px;
}
@media (max-width: 991px) {
	.form-lt {
		padding-top: 100px;
		padding-bottom: 60px;
	}
}
@media (max-width: 767px) {
	.form-lt {
		padding-top: 60px;
	}
}
.form-lt h1 {
	margin-bottom: 77px;
	font-size: 56px;
	font-weight: 600;
	line-height: 56px;
	color: #292b2c;
	text-align: center;
}
@media (max-width: 767px) {
	.form-lt h1 span {
		font-size: 46px;
		line-height: 46px;
	}
}
@media (max-width: 767px) {
	.form-lt h1 {
		margin-bottom: 57px;
		font-size: 28px;
		line-height: 28px;
	}
}
.form-lt .form {
	max-width: 980px;
}
.wpcf7 .screen-reader-response {
	display: none;
}
@media (min-width: 768px) {
	.wpcf7-submit {
		width: auto !important;
	}
}
.wpcf7-response-output {
	clear: both;
	font-size: 15px;
	line-height: 1.66667;
}
.tim-checkbox-label {
	display: block;
	position: relative;
	padding: 5px 0 5px 53px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 300 !important;
}
.tim-checkbox-label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.checkmark {
	position: absolute;
	top: 11px;
	left: 20px;
	height: 18px;
	width: 18px;
	border: 1px solid #000;
	border-radius: 50%;
}
.tim-checkbox-label input:checked ~ .checkmark {
	background-color: #3fa9f5;
	border-color: #3fa9f5;
}
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.tim-checkbox-label input:checked ~ .checkmark:after {
	display: block;
}
.tim-checkbox-label .checkmark:after {
	left: 5px;
	top: 1px;
	width: 7px;
	height: 12px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
.tim-radio-label {
	display: block;
	position: relative;
	padding: 5px 0 5px 53px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 300 !important;
}
.tim-radio-label input {
	position: absolute;
	opacity: 0;
}
.tim-radio-label input:checked ~ .checkmark {
	background-color: #3fa9f5;
	border-color: #3fa9f5;
	border-width: 2px;
}
.tim-radio-label input:checked ~ .checkmark:after {
	display: block;
}
.tim-radio-label .checkmark {
	position: absolute;
	top: 9px;
	left: 20px;
	height: 16px;
	width: 16px;
	border-radius: 50%;
}
.tim-radio-label .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	border: 2px solid #fff;
}
.search-form-container {
	max-height: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	background-color: #f4f4f4;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.search-form-container * {
	visibility: hidden;
}
.search-form-container.search-form-container__active {
	max-height: 200px;
}
.search-form-container.search-form-container__active * {
	visibility: visible;
}
.search-form-container form {
	padding: 1.5rem 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.search-form-container form .search-field {
	border-radius: 0.25rem;
	border: 1px solid #3fa9f5;
	padding: 10px 10px 7px 10px !important;
	width: calc(100vw - 1.2rem);
	color: #292b2c;
}
.search-form-container form button {
	height: 44px;
	padding: 0 2.2rem;
	background-color: #3fa9f5;
	border: none;
	color: #fff;
	border-radius: 0.2rem;
	font-weight: 400;
}
@media (min-width: 768px) {
	.search-form-container form {
		display: block;
	}
	.search-form-container form .search-field {
		width: 400px;
	}
	.search-form-container form button {
		margin-left: 1.4rem;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Fira Sans, sans-serif;
}
.tim-section-default {
	position: relative;
	z-index: 0;
	padding: 100px 0;
	overflow: hidden;
}
.tim-section-default img {
	display: inline;
}
.tim-section-bg {
	background: #f6fbff;
}
@media (max-width: 767px) {
	.tim-section-bg-mobile {
		background: #f6fbff !important;
	}
}
.tim-section-light {
	background: #fff;
}
.tim-section-dark {
	background: #3fa9f5;
}
.tim-section-dark h2,
.tim-section-dark h3 {
	color: #fff !important;
}
.tim-section-dark .title {
	font-weight: 600;
}
.tim-section-top {
	z-index: 10;
}
.tim-section-center {
	text-align: center;
}
.tim-section-center h2,
.tim-section-center h3,
.tim-section-center h4,
.tim-section-center p {
	text-align: center;
}
.tim-section-header {
	padding-bottom: 40px;
	margin-bottom: 40px;
}
.tim-section-header p {
	font-size: 16px;
	line-height: 2;
	font-family: Fira Sans, sans-serif;
}
.tim-section-header p .large-subheader {
	font-size: 22px;
}
h2 {
	font-size: 35px;
	line-height: 1.6;
	padding-bottom: 42px;
}
@media (min-width: 768px) {
	h2 {
		font-size: 50px;
	}
}
h2.border {
	margin-bottom: 45px;
}
h2 small {
	font-size: 25px;
	font-weight: 300;
}
h2 strong:before {
	height: 16px;
}
h3 {
	font-size: 22px;
	padding-bottom: 32px;
}
@media (min-width: 768px) {
	h3 {
		font-size: 35px;
	}
}
h3.border {
	margin-bottom: 32px;
	padding-bottom: 32px;
}
h3.title {
	line-height: 1.2;
}
h3.title strong:before {
	height: 15px;
	bottom: 4px;
}
h3 small {
	font-size: 22px;
}
h3 small strong:before {
	display: none !important;
}
h4 {
	font-size: 30px;
	padding-bottom: 32px;
}
h4.title {
	letter-spacing: -0.5px;
}
h4.title strong:before {
	height: 14px;
	bottom: 2px;
}
h5 {
	font-weight: 600;
	font-size: 30px;
}
.title {
	line-height: 1.33333;
	font-weight: 300;
	font-family: Fira Sans;
}
.title strong {
	font-weight: 600;
	display: inline-block;
	position: relative;
	padding: 0 3px 0 2px;
	margin: 0 -3px 0 -2px;
}
.title strong:before {
	display: block;
	content: "";
	width: 100%;
	min-height: 10px;
	background: #c6f1ff;
	z-index: -1;
	position: absolute;
	bottom: 10px;
	left: 0;
}
.tim-divider {
	width: 100%;
	padding-top: 40px;
	margin-bottom: 40px;
	position: relative;
}
@media (min-width: 768px) {
	.tim-divider {
		padding-top: 80px;
		margin-bottom: 50px;
	}
}
.tim-divider:after {
	content: "";
	display: block;
	width: 60px;
	height: 2px;
	background: #3fa9f5;
	position: absolute;
	bottom: 0;
	left: 41%;
}
@media (min-width: 768px) {
	.tim-divider:after {
		left: 47.4%;
	}
}
.tim-video-default {
	-webkit-box-shadow: 0 29px 80px 0 rgba(54, 57, 73, 0.3);
	box-shadow: 0 29px 80px 0 rgba(54, 57, 73, 0.3);
	margin-bottom: 50px;
	position: relative;
	padding: 0 0 50% 0;
	height: 0;
}
.tim-video-default iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 2px;
}
@media (min-width: 1200px) {
	.tim-video-default iframe {
		min-height: 360px;
	}
}
.tim-video-decorator {
	max-width: 100%;
	padding: 25px 0 50% 0;
	position: relative;
}
@media (min-width: 768px) {
	.tim-video-decorator {
		padding: 5px 50px 0 50px;
		display: inline-block;
		border-radius: 2px;
		max-width: none;
	}
	.tim-video-decorator:before {
		background: #c6f1ff;
		content: "";
		display: block;
		top: 35px;
		left: 0;
		right: 0;
		bottom: 30px;
		position: absolute;
		z-index: -1;
		border-radius: 2px;
	}
}

.tim-video-decorator iframe {
	display: block;
	-webkit-box-shadow: 0 29px 60px 0 rgba(54, 57, 73, 0.09);
	box-shadow: 0 29px 60px 0 rgba(54, 57, 73, 0.09);
}
@media (max-width: 767px) {
	.tim-video-decorator iframe {
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.tim-text-label-1 {
	text-transform: uppercase;
	color: #3fa9f5;
}
.tim-disclaimer {
	font-size: 12px;
	font-family: Fira Sans;
	color: #a0a3a7;
}
#toc_container.have_bullets,
#toc_container.toc_light_blue {
	background: #f6fbff;
	border: none;
	max-width: 600px;
	margin-bottom: 1.666666666667em;
}
.toc_list li .toc_number {
	color: #292b2c;
}
.toc_list li:before {
	display: none !important;
}
.optiscroll {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.optiscroll.is-enabled {
	overflow: hidden;
}
.optiscroll.is-enabled > .optiscroll-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}
.optiscroll-h,
.optiscroll-v {
	position: absolute;
	visibility: hidden;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.optiscroll-v {
	right: 0;
}
.optiscroll-h {
	bottom: 0;
}
.optiscroll.has-htrack > .optiscroll-h,
.optiscroll.has-vtrack > .optiscroll-v {
	visibility: visible;
}
.optiscroll.is-rtl > .optiscroll-v {
	left: 0;
	right: auto;
}
.optiscroll-htrack,
.optiscroll-vtrack {
	display: block;
	position: absolute;
	opacity: 1;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: height 0.2s ease 0s, width 0.2s ease 0s,
		opacity 0.2s ease 0s;
	transition: height 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.optiscroll-v {
	top: 4px;
	bottom: 4px;
	width: 0;
}
.optiscroll-h {
	left: 4px;
	right: 4px;
	height: 0;
}
.optiscroll.has-vtrack.has-htrack > .optiscroll-v {
	bottom: 8px;
}
.optiscroll.has-vtrack.has-htrack > .optiscroll-h {
	right: 8px;
}
.optiscroll-htrack,
.optiscroll-vtrack {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 2px;
	-webkit-box-shadow: 0 0 1px #fff;
	box-shadow: 0 0 1px #fff;
	opacity: 0;
}
.optiscroll-vtrack {
	width: 3px;
	right: 4px;
}
.optiscroll-htrack {
	height: 3px;
	bottom: 4px;
}
.optiscroll:hover > .optiscroll-h .optiscroll-htrack,
.optiscroll:hover > .optiscroll-v .optiscroll-vtrack {
	opacity: 1;
}
.optiscroll.has-vtrack.has-htrack.is-rtl > .optiscroll-h {
	right: 4px;
	left: 8px;
}
.optiscroll.is-rtl > .optiscroll-v .optiscroll-vtrack {
	right: auto;
	left: 4px;
}
.site-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 6px;
}
.site-logo svg {
	position: relative;
	width: 186px !important;
	height: 24px !important;
}
.site-logo .menu-item {
	font-size: 12px;
	line-height: 1.66667;
	margin: 0 0 0 19px;
	position: relative;
}
.site-logo .sub-menu {
	right: 0;
	left: initial;
}
@media (min-width: 480px) {
	.site-logo .sub-menu {
		left: 21px;
		right: initial;
	}
}
.site-logo .lang {
	display: block;
	position: relative;
	padding: 3px 23px 3px 21px;
}
.site-logo .lang:after,
.site-logo .lang:before {
	content: "";
	position: absolute;
}
.site-logo .lang:before {
	width: 1px;
	height: 100%;
	top: 0;
	left: 0;
	background: #fff;
	opacity: 0.2;
}
.site-logo .lang:after {
	top: 50%;
	right: 2px;
	width: 7px;
	height: 7px;
	margin-top: -5px;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	-webkit-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0.5;
}
.site-logo .lang:hover:after {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.menu li {
	float: left;
}
.tim-top-bar .menu a {
	padding: 5px 10px;
}
.site-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-flow: wrap;
	flex-flow: wrap;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.site-menu .menu-item {
	position: relative;
	margin: 0 0 0 0;
}
@media (min-width: 768px) {
	.site-menu .menu-item {
		margin-left: 25px !important;
	}
}
@media (min-width: 1200px) {
	.site-menu .menu-item {
		margin-left: 45px !important;
	}
	.site-menu .sub-menu .menu-item {
		margin-left: 0 !important;
	}
}

.site-menu .menu-item.btn-wrap-wrw a {
	border-color: #fff;
}
.site-menu .current-menu-item a {
	border-bottom: solid #c6f1ff 1px;
}
.site-menu .menu-item-has-children a:hover {
	border-color: #3fa9f5;
}

.menu-item:hover .sub-menu {
	opacity: 1;
	visibility: visible;
}
.sub-menu .menu-item {
	margin: 0;
	font-size: 12px;
	line-height: 1.66667;
	width: 100%;
}
.sub-menu .menu-item a {
	display: block;
	color: #292b2c;
	padding: 5px 30px;
	border: 0;
}
.sub-menu .menu-item a:hover {
	color: #3fa9f5;
	border: 0;
}
.sub-menu .menu-item-active a {
	color: #aeb0b3;
}
.site-footer {
	background: #222222;
	color: #fff;
	clear: both;
	padding: 35px 0 0 0;
	font-size: 12px;
	line-height: 1.66667;
	text-align: center;
	color: #fff;
	clear: both;
	padding: 35px 0 0 0;
	font-size: 12px;
	line-height: 1.66667;
	text-align: center;
}
.site-footer a {
	text-decoration: none;
	color: #fff;
	font-weight: 500;
	text-decoration: none;
	color: #fff;
	font-weight: 500;
}
.site-footer p {
	font-size: 16px;
	font-size: 16px;
}
.site-footer ul {
	font-size: 16px;
	font-size: 16px;
}
.site-footer .logo-menu {
	margin-bottom: 32px;
	margin-bottom: 32px;
}
.site-footer .title {
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
}
.site-footer .site-logo {
	margin-bottom: 25px;
	margin-bottom: 25px;
}
.site-footer .site-menu {
	letter-spacing: 0.025em;
	letter-spacing: 0.025em;
}
.site-footer .site-menu > .menu-item {
	margin-left: 0;
	display: none;
	margin-left: 0;
	display: none;
}
.site-footer .site-menu > .menu-item-has-children {
	letter-spacing: 0;
	letter-spacing: 0;
}
.site-footer .site-menu a {
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
}
.site-footer .site-menu .btn-wrap-s {
	display: inline-block;
	width: 100%;
	display: inline-block;
	width: 100%;
}
.site-footer .site-menu .btn-wrap-s a {
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
}
.site-footer .about-contact-links {
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
}
.site-footer .footer-site-menu {
	padding: 35px 0;
	padding: 35px 0;
}
.site-footer .footer-site-menu .site-menu .menu-item {
	display: block;
	width: 50%;
	display: block;
	width: 50%;
}
.site-footer .footer-site-menu .site-menu .menu-item a {
	font-size: 15px;
	font-size: 15px;
}
.site-footer .tim-box-about {
	text-align: left;
	text-align: left;
}
.site-footer .tim-box-about img {
	float: left;
	margin: -10px 25px 15px 0;
	float: left;
	margin: -10px 25px 15px 0;
}
.site-footer .footer-list li {
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
}

.site-footer .footer-list li a {
	border-bottom: 1px solid #9fd4fa;
	border-bottom: 1px solid #9fd4fa;
}
.site-footer .copyright-links {
	background: #111111;
	letter-spacing: 0.05em;
	padding: 40px 0;
	font-size: 12px;
	letter-spacing: 0.05em;
	padding: 40px 0;
	font-size: 12px;
}
.site-footer .copyright-links p {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .copyright-links ul {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .copyright-links .copy {
	margin-bottom: 5px;
	margin-bottom: 5px;
}
.site-footer .copyright-links .copy-url {
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
}
.site-footer .copyright-links .copy-url:hover {
	border-color: #fff;
	border-color: #fff;
}
.site-footer .copyright-links .menu-bottom li {
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
}
.site-footer .copyright-links .menu-bottom li a {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .sub-menu {
	top: initial;
	bottom: 100%;
	top: initial;
	bottom: 100%;
}
.site-header {
	background: #3fa9f5;
	color: #fff;
	clear: both;
	padding: 28px 0 28px 0;
	font-size: 15px;
	line-height: 1.66667;
	background: #3fa9f5;
	color: #fff;
	clear: both;
	padding: 28px 0 28px 0;
	font-size: 15px;
	line-height: 1.66667;
}
.site-header a {
	text-decoration: none;
	color: #fff;
	text-decoration: none;
	color: #fff;
}
.site-header .inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.site-header .site-nav {
	display: none;
	display: none;
}
.site-header .hide {
	display: none;
	display: none;
}
.site-header .toggle {
	display: inline-block;
	cursor: pointer;
	width: 28px;
	height: 21px;
	border-top: solid #fff 3px;
	position: relative;
	margin: 2px 0 3px 0;
	display: inline-block;
	cursor: pointer;
	width: 28px;
	height: 21px;
	border-top: solid #fff 3px;
	position: relative;
	margin: 2px 0 3px 0;
}
.site-header .toggle:after {
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
}
.site-header .toggle:before {
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	top: 6px;
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	top: 6px;
}
#nav-toggle {
	display: none;
}
#nav-toggle:checked + .site-header {
	background: #fff;
	color: #292b2c;
	height: 100vh;
	overflow: auto;
}
#nav-toggle:checked + .site-header .svg-path {
	fill: #292b2c !important;
}
#nav-toggle:checked + .site-header:hover .svg-path {
	fill: #292b2c !important;
}
#nav-toggle:checked + .site-header .site-logo .svg {
	width: 186px !important;
	height: 24px !important;
}
#nav-toggle:checked + .site-header .site-logo .menu-item {
	display: none;
}
#nav-toggle:checked + .site-header .inner {
	-ms-flex-flow: wrap;
	flex-flow: wrap;
}
#nav-toggle:checked + .site-header .site-nav {
	display: block;
	width: 100%;
}
#nav-toggle:checked + .site-header .site-menu {
	margin-top: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
#nav-toggle:checked + .site-header .site-menu .menu-item {
	margin-left: 0;
	width: 100%;
	text-align: center;
}
#nav-toggle:checked + .site-header .site-menu a {
	color: #292b2c;
	border: 0;
	display: block;
	padding: 10px 0;
	font-size: 24px;
	line-height: 1.41667;
	font-weight: 400;
}
#nav-toggle:checked + .site-header .site-menu a:hover {
	color: #3fa9f5 !important;
}
#nav-toggle:checked + .site-header .site-menu .menu-item-active > a {
	color: #aeb0b3;
}
#nav-toggle:checked + .site-header .btn-wrap a {
	text-transform: initial;
	letter-spacing: initial;
	background: initial;
	border: 0;
}
#nav-toggle:checked + .site-header .sub-menu {
	all: unset;
}
#nav-toggle:checked + .site-header .sub-menu a {
	font-size: 18px;
	line-height: 1.66667;
	font-weight: 300;
}
#nav-toggle:checked + .site-header .toggle:hover:after,
#nav-toggle:checked + .site-header .toggle:hover:before {
	background: #292b2c;
}
#nav-toggle:checked + .site-header .toggle:after,
#nav-toggle:checked + .site-header .toggle:before {
	background: #292b2c;
}
#nav-toggle:checked + .site-header .toggle:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
#nav-toggle:checked + .site-header .toggle:after {
	top: 6px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.site-footer {
	padding: 35px 0 0 0;
	font-size: 12px;
	line-height: 1.66667;
	text-align: center;
}
.site-footer p,
.site-footer ul {
	font-size: 16px;
}
.site-footer a {
	font-weight: 500;
}
@media (min-width: 768px) {
	.site-footer {
		text-align: left;
	}
}
.site-footer .logo-menu {
	margin-bottom: 32px;
}
@media (min-width: 480px) {
	.site-footer .logo-menu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
@media (min-width: 768px) {
	.site-footer .logo-menu {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}
.site-footer .title {
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
}
.site-footer .site-logo {
	margin-bottom: 25px;
}
@media (min-width: 480px) {
	.site-footer .site-logo {
		margin-bottom: 0;
	}
}
.site-footer .site-menu {
	letter-spacing: 0.025em;
}
.site-footer .site-menu > .menu-item {
	margin-left: 0;
	display: none;
}
@media (min-width: 480px) {
	.site-footer .site-menu > .menu-item {
		margin-left: 25px;
	}
	.site-footer .site-menu > .menu-item:first-child {
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.site-footer .site-menu > .menu-item {
		display: block;
	}
}
@media (min-width: 1200px) {
	.site-footer .site-menu > .menu-item {
		margin-left: 50px;
	}
}
.site-footer .site-menu > .menu-item-has-children {
	letter-spacing: 0;
}
.site-footer .site-menu a {
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
}
.site-footer .site-menu .btn-wrap-s {
	display: inline-block;
	width: 100%;
}
@media (min-width: 480px) {
	.site-footer .site-menu .btn-wrap-s {
		display: block;
		width: auto;
	}
}
.site-footer .site-menu .btn-wrap-s a {
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
}
.site-footer .about-contact-links {
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
}
.site-footer .footer-site-menu {
	padding: 35px 0;
}
.site-footer .tim-box-about {
	text-align: left;
}
@media (min-width: 1200px) {
	.site-footer .tim-box-about {
		width: 70%;
	}
}
.site-footer .tim-box-about img {
	float: left;
	margin: -10px 25px 15px 0;
}
.site-footer .footer-list li {
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
}
.site-footer .footer-list li a {
	border-bottom: 1px solid #9fd4fa;
}
.site-footer .copyright-links {
	background: #111111;
	letter-spacing: 0.05em;
	padding: 40px 0;
	font-size: 12px;
}
.site-footer .copyright-links p,
.site-footer .copyright-links ul {
	font-size: 12px;
}
@media (min-width: 768px) {
	.site-footer .copyright-links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.site-footer .copyright-links .copy {
	margin-bottom: 5px;
}
@media (min-width: 768px) {
	.site-footer .copyright-links .copy {
		margin-bottom: 0;
	}
}
.site-footer .copyright-links .copy-url {
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
}
.site-footer .copyright-links .copy-url:hover {
	border-color: #fff;
}
.site-footer .copyright-links .menu-bottom li {
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
	display: block;
}
.site-footer .copyright-links .menu-bottom li a {
	font-size: 12px;
}
.site-footer .sub-menu {
	top: initial;
	bottom: 100%;
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
	.col-sm-1 {
		float: left;
		width: 8.33333%;
	}
	.col-sm-10 {
		float: left;
		width: 83.33333%;
	}
	.col-sm-11 {
		float: left;
		width: 91.66667%;
	}
	.col-sm-12 {
		float: left;
		width: 100%;
	}
	.col-sm-2 {
		float: left;
		width: 16.66667%;
	}
	.col-sm-3 {
		float: left;
		width: 25%;
	}
	.col-sm-4 {
		float: left;
		width: 33.33333%;
	}
	.col-sm-5 {
		float: left;
		width: 41.66667%;
	}
	.col-sm-6 {
		float: left;
		width: 50%;
	}
	.col-sm-7 {
		float: left;
		width: 58.33333%;
	}
	.col-sm-8 {
		float: left;
		width: 66.66667%;
	}
	.col-sm-9 {
		float: left;
		width: 75%;
	}
	.col-sm-pull-0 {
		right: auto;
	}
	.col-sm-pull-1 {
		right: 8.33333%;
	}
	.col-sm-pull-2 {
		right: 16.66667%;
	}
	.col-sm-pull-3 {
		right: 25%;
	}
	.col-sm-pull-4 {
		right: 33.33333%;
	}
	.col-sm-pull-5 {
		right: 41.66667%;
	}
	.col-sm-pull-6 {
		right: 50%;
	}
	.col-sm-pull-7 {
		right: 58.33333%;
	}
	.col-sm-pull-8 {
		right: 66.66667%;
	}
	.col-sm-pull-9 {
		right: 75%;
	}
	.col-sm-pull-10 {
		right: 83.33333%;
	}
	.col-sm-pull-11 {
		right: 91.66667%;
	}
	.col-sm-pull-12 {
		right: 100%;
	}
	.col-sm-push-0 {
		left: auto;
	}
	.col-sm-push-1 {
		left: 8.33333%;
	}
	.col-sm-push-2 {
		left: 16.66667%;
	}
	.col-sm-push-3 {
		left: 25%;
	}
	.col-sm-push-4 {
		left: 33.33333%;
	}
	.col-sm-push-5 {
		left: 41.66667%;
	}
	.col-sm-push-6 {
		left: 50%;
	}
	.col-sm-push-7 {
		left: 58.33333%;
	}
	.col-sm-push-8 {
		left: 66.66667%;
	}
	.col-sm-push-9 {
		left: 75%;
	}
	.col-sm-push-10 {
		left: 83.33333%;
	}
	.col-sm-push-11 {
		left: 91.66667%;
	}
	.col-sm-push-12 {
		left: 100%;
	}
	.col-sm-offset-0 {
		margin-left: 0;
	}
	.col-sm-offset-1 {
		margin-left: 8.33333%;
	}
	.col-sm-offset-2 {
		margin-left: 16.66667%;
	}
	.col-sm-offset-3 {
		margin-left: 25%;
	}
	.col-sm-offset-4 {
		margin-left: 33.33333%;
	}
	.col-sm-offset-5 {
		margin-left: 41.66667%;
	}
	.col-sm-offset-6 {
		margin-left: 50%;
	}
	.col-sm-offset-7 {
		margin-left: 58.33333%;
	}
	.col-sm-offset-8 {
		margin-left: 66.66667%;
	}
	.col-sm-offset-9 {
		margin-left: 75%;
	}
	.col-sm-offset-10 {
		margin-left: 83.33333%;
	}
	.col-sm-offset-11 {
		margin-left: 91.66667%;
	}
	.col-sm-offset-12 {
		margin-left: 100%;
	}
	.border:after {
		left: 47.4%;
	}
	.tim-pull-right {
		float: right !important;
	}
	.width-90-center {
		width: 90%;
		margin: 0 auto;
	}
	.width-80-center {
		width: 80%;
		margin: 0 auto;
	}
	.width-70-center {
		width: 70%;
		margin: 0 auto;
	}
	.width-65-center {
		width: 65%;
		margin: 0 auto;
	}
	.width-60-center {
		width: 60%;
		margin: 0 auto;
	}
	.width-55-center {
		width: 55%;
		margin: 0 auto;
	}
	.tim-img-md-screen {
		display: block !important;
	}
	.tim-img-small-screen {
		display: none !important;
	}
	.size-20 {
		font-size: 20px;
		line-height: 1.8;
	}
	.size-22 {
		font-size: 22px;
		line-height: 1.63636;
	}
	.size-24 {
		font-size: 24px;
		line-height: 1.41667;
	}
	.size-34 {
		font-size: 34px;
		line-height: 1.17647;
	}
	.size-36 {
		font-size: 36px;
		line-height: 1.02778;
	}
	.size-40 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-48 {
		font-size: 48px;
		line-height: 1.292857143;
	}
	.btn-wrap a.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn-wrap a:visited.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn:visited.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn-group-lg li {
		margin: 0;
	}
	.btn-group-lg li .btn {
		min-width: 195px;
		border-right: none;
		border-radius: 0;
	}
	.btn-group-lg li:first-child .btn {
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}
	.btn-group-lg li.btn {
		min-width: 195px;
		border-right: none;
		border-radius: 0;
	}
	.nice-select.wide {
		height: 75px;
	}
	.nice-select.wide span {
		padding: 17px 25px 0 40px;
		font-size: 34px;
		line-height: 38px;
	}
	.wpcf7-submit {
		width: auto !important;
	}
	h2 {
		font-size: 50px;
		font-size: 50px;
	}
	h3 {
		font-size: 35px;
		font-size: 35px;
	}
	.tim-divider {
		padding-top: 80px;
		margin-bottom: 50px;
		padding-top: 80px;
		margin-bottom: 50px;
	}
	.tim-divider:after {
		left: 47.4%;
		left: 47.4%;
	}
	.tim-video-decorator {
		padding: 5px 50px 0 50px;
		display: inline-block;
		border-radius: 2px;
		max-width: none;
		padding: 5px 50px 0 50px;
		display: inline-block;
		border-radius: 2px;
		max-width: none;
	}
	.tim-video-decorator:before {
		background: #c6f1ff;
		content: "";
		display: block;
		top: 35px;
		left: 0;
		right: 0;
		bottom: 30px;
		position: absolute;
		z-index: -1;
		border-radius: 2px;
		background: #c6f1ff;
		content: "";
		display: block;
		top: 35px;
		left: 0;
		right: 0;
		bottom: 30px;
		position: absolute;
		z-index: -1;
		border-radius: 2px;
	}
	.site-header .site-nav {
		display: block;
		display: block;
	}
	.site-header .toggle {
		display: none;
		display: none;
	}
	.site-footer {
		text-align: left;
		text-align: left;
	}
	.site-footer .logo-menu {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.site-footer .site-menu > .menu-item {
		display: block;
		display: block;
	}
	.site-footer .copyright-links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.site-footer .copyright-links .copy {
		margin-bottom: 0;
		margin-bottom: 0;
	}
	.page-header textarea {
		padding-right: 30px;
		padding-left: 30px;
	}
	.entry-header h1 {
		font-size: 38px;
		font-size: 38px;
	}
	.widget-testimonial .np-testimonial-text {
		font-size: 20px;
		line-height: 2.2;
		font-size: 20px;
		line-height: 2.2;
	}
	.widget-best-content .btn-group-lg {
		margin: 0 -40px;
		margin: 0 -40px;
	}
	.entry-share .title {
		display: block;
		font-weight: 400;
	}
	.entry-share .share-list .share:first-child {
		margin-left: 35px;
	}
	.page-opting-in-template .tim-video-decorator {
		margin-left: 0;
		margin-right: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.page-opting-in-template .tim-video-decorator .tim-video-decorator-inside {
		height: 430px;
		margin-left: 0;
		margin-right: 0;
	}
	.page-opting-in-template .menu li {
		width: 33%;
		float: left;
		padding: 10px;
	}
	.tim-page-about {
		padding: 90px 0 100px;
	}
	.tim-page-about .title {
		font-size: 47px;
	}
	.tim-page-about .title strong:before {
		bottom: 10px;
	}
	.tim-page-about .title.border {
		padding-bottom: 32px;
		margin-bottom: 35px;
	}
	.tim-page-about .tim-video-decorator {
		margin-left: auto;
		margin-right: auto;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
	.col-md-1 {
		float: left;
		width: 8.33333%;
	}
	.col-md-10 {
		float: left;
		width: 83.33333%;
	}
	.col-md-11 {
		float: left;
		width: 91.66667%;
	}
	.col-md-12 {
		float: left;
		width: 100%;
	}
	.col-md-2 {
		float: left;
		width: 16.66667%;
	}
	.col-md-3 {
		float: left;
		width: 25%;
	}
	.col-md-4 {
		float: left;
		width: 33.33333%;
	}
	.col-md-5 {
		float: left;
		width: 41.66667%;
	}
	.col-md-6 {
		float: left;
		width: 50%;
	}
	.col-md-7 {
		float: left;
		width: 58.33333%;
	}
	.col-md-8 {
		float: left;
		width: 66.66667%;
	}
	.col-md-9 {
		float: left;
		width: 75%;
	}
	.col-md-pull-0 {
		right: auto;
	}
	.col-md-pull-1 {
		right: 8.33333%;
	}
	.col-md-pull-2 {
		right: 16.66667%;
	}
	.col-md-pull-3 {
		right: 25%;
	}
	.col-md-pull-4 {
		right: 33.33333%;
	}
	.col-md-pull-5 {
		right: 41.66667%;
	}
	.col-md-pull-6 {
		right: 50%;
	}
	.col-md-pull-7 {
		right: 58.33333%;
	}
	.col-md-pull-8 {
		right: 66.66667%;
	}
	.col-md-pull-9 {
		right: 75%;
	}
	.col-md-pull-10 {
		right: 83.33333%;
	}
	.col-md-pull-11 {
		right: 91.66667%;
	}
	.col-md-pull-12 {
		right: 100%;
	}
	.col-md-push-0 {
		left: auto;
	}
	.col-md-push-1 {
		left: 8.33333%;
	}
	.col-md-push-2 {
		left: 16.66667%;
	}
	.col-md-push-3 {
		left: 25%;
	}
	.col-md-push-4 {
		left: 33.33333%;
	}
	.col-md-push-5 {
		left: 41.66667%;
	}
	.col-md-push-6 {
		left: 50%;
	}
	.col-md-push-7 {
		left: 58.33333%;
	}
	.col-md-push-8 {
		left: 66.66667%;
	}
	.col-md-push-9 {
		left: 75%;
	}
	.col-md-push-10 {
		left: 83.33333%;
	}
	.col-md-push-11 {
		left: 91.66667%;
	}
	.col-md-push-12 {
		left: 100%;
	}
	.col-md-offset-0 {
		margin-left: 0;
	}
	.col-md-offset-1 {
		margin-left: 8.33333%;
	}
	.col-md-offset-2 {
		margin-left: 16.66667%;
	}
	.col-md-offset-3 {
		margin-left: 25%;
	}
	.col-md-offset-4 {
		margin-left: 33.33333%;
	}
	.col-md-offset-5 {
		margin-left: 41.66667%;
	}
	.col-md-offset-6 {
		margin-left: 50%;
	}
	.col-md-offset-7 {
		margin-left: 58.33333%;
	}
	.col-md-offset-8 {
		margin-left: 66.66667%;
	}
	.col-md-offset-9 {
		margin-left: 75%;
	}
	.col-md-offset-10 {
		margin-left: 83.33333%;
	}
	.col-md-offset-11 {
		margin-left: 91.66667%;
	}
	.col-md-offset-12 {
		margin-left: 100%;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	.col-lg-1 {
		float: left;
		width: 8.33333%;
	}
	.col-lg-10 {
		float: left;
		width: 83.33333%;
	}
	.col-lg-11 {
		float: left;
		width: 91.66667%;
	}
	.col-lg-12 {
		float: left;
		width: 100%;
	}
	.col-lg-2 {
		float: left;
		width: 16.66667%;
	}
	.col-lg-3 {
		float: left;
		width: 25%;
	}
	.col-lg-4 {
		float: left;
		width: 33.33333%;
	}
	.col-lg-5 {
		float: left;
		width: 41.66667%;
	}
	.col-lg-6 {
		float: left;
		width: 50%;
	}
	.col-lg-7 {
		float: left;
		width: 58.33333%;
	}
	.col-lg-8 {
		float: left;
		width: 66.66667%;
	}
	.col-lg-9 {
		float: left;
		width: 75%;
	}
	.col-lg-pull-0 {
		right: auto;
	}
	.col-lg-pull-1 {
		right: 8.33333%;
	}
	.col-lg-pull-2 {
		right: 16.66667%;
	}
	.col-lg-pull-3 {
		right: 25%;
	}
	.col-lg-pull-4 {
		right: 33.33333%;
	}
	.col-lg-pull-5 {
		right: 41.66667%;
	}
	.col-lg-pull-6 {
		right: 50%;
	}
	.col-lg-pull-7 {
		right: 58.33333%;
	}
	.col-lg-pull-8 {
		right: 66.66667%;
	}
	.col-lg-pull-9 {
		right: 75%;
	}
	.col-lg-pull-10 {
		right: 83.33333%;
	}
	.col-lg-pull-11 {
		right: 91.66667%;
	}
	.col-lg-pull-12 {
		right: 100%;
	}
	.col-lg-push-0 {
		left: auto;
	}
	.col-lg-push-1 {
		left: 8.33333%;
	}
	.col-lg-push-2 {
		left: 16.66667%;
	}
	.col-lg-push-3 {
		left: 25%;
	}
	.col-lg-push-4 {
		left: 33.33333%;
	}
	.col-lg-push-5 {
		left: 41.66667%;
	}
	.col-lg-push-6 {
		left: 50%;
	}
	.col-lg-push-7 {
		left: 58.33333%;
	}
	.col-lg-push-8 {
		left: 66.66667%;
	}
	.col-lg-push-9 {
		left: 75%;
	}
	.col-lg-push-10 {
		left: 83.33333%;
	}
	.col-lg-push-11 {
		left: 91.66667%;
	}
	.col-lg-push-12 {
		left: 100%;
	}
	.col-lg-offset-0 {
		margin-left: 0;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%;
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%;
	}
	.col-lg-offset-3 {
		margin-left: 25%;
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%;
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%;
	}
	.col-lg-offset-6 {
		margin-left: 50%;
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%;
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%;
	}
	.col-lg-offset-9 {
		margin-left: 75%;
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%;
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%;
	}
	.col-lg-offset-12 {
		margin-left: 100%;
	}
	.nl {
		display: block;
	}
	.size-56 {
		font-size: 56px;
		line-height: 1;
	}
	.size-84 {
		font-size: 84px;
		line-height: 1.11905;
	}
	.tim-video-default iframe {
		min-height: 360px;
		min-height: 360px;
	}
	.site-footer .site-menu > .menu-item {
		margin-left: 50px;
		margin-left: 50px;
	}
	.site-footer .footer-site-menu .site-menu .menu-item {
		width: auto;
		width: auto;
	}
	.site-footer .tim-box-about {
		width: 70%;
		width: 70%;
	}
	.widget-default .tim-widget-description {
		margin-right: auto;
		margin-left: auto;
		margin-right: auto;
		margin-left: auto;
	}
	.widget-default .title {
		margin-right: auto;
		margin-left: auto;
		margin-right: auto;
		margin-left: auto;
	}
	.page-content {
		padding: 0 40px 0 0;
	}
	.tim-sidebar-content {
		padding: 30px 0 0 20px;
	}
	.page-penny-stocking {
		padding-bottom: 50px;
	}
}
@media (max-width: 767px) {
	.col-50 {
		width: 100%;
	}
	.nice-select.wide:after {
		right: 3px;
	}
	.nice-select.wide span {
		height: 60px;
		padding-left: 0;
	}
	.nice-select.wide .list {
		top: 60px;
	}
	.transparent .nice-select:after {
		right: 3px;
	}
	.transparent .nice-select span {
		padding-left: 0;
	}
	.transparent input[type="submit"] {
		width: 100%;
	}
	.transparent .wpcf7-not-valid-tip {
		width: 100%;
		padding: 10px 0;
	}
	.transparent .wpcf7-response-output {
		padding: 10px;
	}
	.transparent.with-gray .nice-select:after {
		right: 3px;
	}
	.transparent.with-gray .nice-select span {
		padding-left: 0;
	}
	.transparent.with-gray input[type="submit"] {
		width: 100%;
	}
	.comment-form input {
		padding: 0 30px;
	}
	.comment-form .form-submit {
		width: 100%;
	}
	.form input {
		padding: 0 30px;
	}
	.form .form-submit {
		width: 100%;
	}
	.comment-form.two-columns fieldset {
		margin-bottom: 15px;
	}
	.comment-form.two-columns input {
		padding: 0;
	}
	.comment-form.two-columns input[type="submit"] {
		top: 15px;
		width: 100%;
	}
	.form.two-columns fieldset {
		margin-bottom: 15px;
	}
	.form.two-columns input {
		padding: 0;
	}
	.form.two-columns input[type="submit"] {
		top: 15px;
		width: 100%;
	}
	.form-lt {
		padding-top: 60px;
	}
	.form-lt h1 {
		margin-bottom: 57px;
		font-size: 28px;
		line-height: 28px;
	}
	.form-lt h1 span {
		font-size: 46px;
		line-height: 46px;
	}
	.tim-section-bg-mobile {
		background: #f6fbff !important;
		background: #f6fbff !important;
	}
	.tim-video-decorator iframe {
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@media (min-width: 480px) {
	.size-40 {
		font-size: 31px;
		line-height: 1.5;
	}
	.size-48 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-56 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-84 {
		font-size: 56px;
		line-height: 1;
	}
	.btn {
		min-width: 140px;
	}
	.btn:visited {
		min-width: 140px;
	}
	.btn-wrap a {
		min-width: 140px;
	}
	.btn-wrap a:visited {
		min-width: 140px;
	}
	input[type="submit"] {
		min-width: 140px;
	}
	.site-logo .sub-menu {
		left: 21px;
		right: initial;
		left: 21px;
		right: initial;
	}
	.site-footer .logo-menu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.site-footer .site-logo {
		margin-bottom: 0;
		margin-bottom: 0;
	}
	.site-footer .site-menu > .menu-item {
		margin-left: 25px;
	}
	.site-footer .site-menu > .menu-item:first-child {
		margin-left: 0;
		margin-left: 0;
	}
	.site-footer .site-menu .btn-wrap-s {
		display: block;
		width: auto;
		display: block;
		width: auto;
	}
	.page-header {
		padding: 100px 0 170px 0;
	}
	.page-header .form {
		margin-top: 100px;
	}
	.page-header .form-analyze {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.page-header .form-analyze:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 2px;
	}
	.page-header .form-analyze .form-text {
		text-align: left;
		margin-bottom: 0;
		background: 0 0;
	}
	.page-header .form-analyze .form-submit {
		width: auto;
	}
	.page-header .arrow {
		bottom: 40px;
	}
	.page-header.arrow {
		padding-bottom: 205px;
	}
	.widget-default {
		padding: 35px 40px 60px 40px;
		padding: 35px 40px 60px 40px;
	}
	.widget-about .text {
		padding: 35px 40px 30px 40px;
		padding: 35px 40px 30px 40px;
	}
	.widget-search .form {
		padding: 0 35px 0 40px;
		padding: 0 35px 0 40px;
	}
	.widget-search .text {
		padding-right: 35px;
		padding-right: 35px;
	}
	.widget-help .title-wrap {
		padding: 50px 30px 45px 30px;
		padding: 50px 30px 45px 30px;
	}
	.widget-testimonial {
		padding: 90px 40px 100px 40px;
		padding: 90px 40px 100px 40px;
	}
	.share-list .url {
		width: 90px;
		padding: 9px 5px;
	}
	.share-list .icon {
		font-size: 20px;
		line-height: 1em;
		padding: 0 2px;
	}
	.share-list .number {
		padding: 0 5px;
	}
	.page-penny-stocking .tim-penny-stocking-navigation .next-div p {
		min-width: 140px;
	}
}
@media (max-width: 1279px) {
	.comment-form.two-columns form {
		margin-right: -10px;
		margin-left: -10px;
	}
	.comment-form.two-columns [class*="col-"] {
		padding: 0 10px;
	}
	.form.two-columns form {
		margin-right: -10px;
		margin-left: -10px;
	}
	.form.two-columns [class*="col-"] {
		padding: 0 10px;
	}
}
@media (max-width: 991px) {
	.form-lt {
		padding-top: 100px;
		padding-bottom: 60px;
	}
}
@media screen and (max-width: 800px) and (orientation: landscape) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}
@media only screen and (max-width: 960px) {
	.main-article {
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
	}
	.entry-header .title {
		font-size: 30px;
		font-size: 30px;
	}
	.comment-heading .entry-subtitle {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
	}
	.comment-list {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-bottom: 50px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-bottom: 50px;
	}
	.comment-respond {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		padding-bottom: 50px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		padding-bottom: 50px;
	}
	#shares {
		display: none !important;
	}
}
@media only screen and (max-width: 767px) {
	.entry-content {
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
	}
	.comment-author {
		font-size: 18px;
		line-height: 1.721428571em;
		font-size: 18px;
		line-height: 1.721428571em;
	}
	.comment-list .comment-reply-title {
		font-size: 18px;
		line-height: 1.721428571em;
		font-size: 18px;
		line-height: 1.721428571em;
	}
	.children .comment-respond {
		margin-left: 17px;
		margin-left: 17px;
	}
	.children .comment-respond .p-email {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.children .comment-respond .p-name {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.children .comment-body {
		padding-left: 15px;
		padding-left: 15px;
	}
	.children .comment-author:after {
		left: -18px;
		left: -18px;
	}
	.comment .children {
		margin-left: 15px;
		margin-left: 15px;
	}
}
@media only screen and (max-width: 479px) {
	.entry-header .title {
		font-size: 27px;
		font-size: 27px;
	}
	.entry-content h2 {
		font-size: 20px;
		font-size: 20px;
	}
	.entry-content h3 {
		font-size: 20px;
		font-size: 20px;
	}
	.comment-respond .p-email {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.comment-respond .p-name {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.np-domain-check {
		padding: 20px 10px;
		padding: 20px 10px;
	}
	.np-domain-check .cols {
		display: block;
		margin-bottom: 10px;
		display: block;
		margin-bottom: 10px;
	}
	.np-domain-check .cols .field-p {
		display: block;
		margin-bottom: 10px;
		display: block;
		margin-bottom: 10px;
	}
	.np-domain-check .cols .field-p:last-child {
		margin-bottom: 0;
		margin-bottom: 0;
	}
	.np-domain-check .cols .field-p-name {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .field-p-submit {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .field-p-tld {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .btn {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.np-domain-check .field-p {
		padding: 0;
		padding: 0;
	}
	.np-domain-check .info-list {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		-moz-column-gap: 0;
		column-gap: 0;
		-webkit-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		column-gap: 0;
	}
	.np-keyword-check .cols {
		margin-bottom: 0;
		margin-bottom: 0;
	}
}
@media (min-width: 960px) {
	.main-aside {
		float: right;
		width: 33.05085%;
		float: right;
		width: 33.05085%;
	}
}
.page-header {
	background: #3fa9f5;
	color: #fff;
	font-size: 20px;
	line-height: 1.8;
	text-align: center;
	padding: 50px 0 75px 0;
	position: relative;
}
@media (min-width: 480px) {
	.page-header {
		padding: 100px 0 170px 0;
	}
	.page-header.arrow {
		padding-bottom: 205px;
	}
}
.page-header .title {
	font-weight: 600;
}
.page-header .description {
	margin: 2em auto 0 auto;
}
.page-header .form {
	margin-top: 50px;
}
@media (min-width: 480px) {
	.page-header .form {
		margin-top: 100px;
	}
}
.page-header .form-analyze {
	max-width: 610px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
}
@media (min-width: 480px) {
	.page-header .form-analyze {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
@media (min-width: 480px) {
	.page-header .form-analyze:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 2px;
	}
}
.page-header .form-analyze .form-submit,
.page-header .form-analyze .form-text {
	border-radius: 2px;
	position: relative;
	z-index: 2;
	width: 100%;
}
.page-header .form-analyze .form-text {
	text-align: center;
	margin-bottom: 1px;
}
@media (min-width: 480px) {
	.page-header .form-analyze .form-text {
		text-align: left;
		margin-bottom: 0;
		background: 0 0;
	}
}
@media (min-width: 480px) {
	.page-header .form-analyze .form-submit {
		width: auto;
	}
}
.page-header .form-analyze .form-submit:hover {
	-webkit-box-shadow: 0 10px 20px -2px rgba(207, 67, 20, 0.5);
	box-shadow: 0 10px 20px -2px rgba(207, 67, 20, 0.5);
}
.page-header textarea {
	background: rgba(255, 255, 255, 0.2);
	padding-right: 10px;
	padding-left: 10px;
	color: #fff;
}
@media (min-width: 768px) {
	.page-header textarea {
		padding-right: 30px;
		padding-left: 30px;
	}
}
.page-header .arrow {
	display: block;
	width: 21px;
	height: 21px;
	position: absolute;
	bottom: 25px;
	left: 50%;
	margin-left: -10.5px;
	text-indent: -9999px;
	overflow: hidden;
}
@media (min-width: 480px) {
	.page-header .arrow {
		bottom: 40px;
	}
}
.page-header .arrow:after,
.page-header .arrow:before {
	content: "";
	display: block;
	position: absolute;
}
.page-header .arrow:before {
	width: 1px;
	height: 100%;
	top: 0;
	left: 50%;
	background: #fff;
	margin-left: -0.5px;
	margin-top: -2px;
	z-index: 2;
}
.page-header .arrow:after {
	top: 50%;
	left: 50%;
	width: 10.5px;
	height: 10.5px;
	border: solid #fff 1px;
	border-top: none;
	border-right: none;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	margin-left: -5.25px;
	margin-top: -2.625px;
	z-index: 1;
}
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}
.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: 0 !important;
}
.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.mfp-container:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
	display: none;
}
.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
	width: 100%;
	cursor: auto;
}
.mfp-ajax-cur {
	cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
	cursor: auto;
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.mfp-loading.mfp-figure {
	display: none;
}
.mfp-hide {
	display: none !important;
}
.mfp-preloader {
	color: #ccc;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}
.mfp-preloader a {
	color: #ccc;
}
.mfp-preloader a:hover {
	color: #fff;
}
.mfp-s-ready .mfp-preloader {
	display: none;
}
.mfp-s-error .mfp-content {
	display: none;
}
button.mfp-arrow,
button.mfp-close {
	overflow: visible;
	cursor: pointer;
	background: 0 0;
	border: 0;
	display: block;
	outline: 0;
	padding: 0;
	z-index: 1046;
	-webkit-box-shadow: none;
	box-shadow: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: #fff;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}
.mfp-close:focus,
.mfp-close:hover {
	opacity: 1;
}
.mfp-close:active {
	top: 1px;
}
.mfp-close-btn-in .mfp-close {
	color: #333;
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
	color: #fff;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #ccc;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}
.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
	margin-top: -54px;
}
.mfp-arrow:focus,
.mfp-arrow:hover {
	opacity: 1;
}
.mfp-arrow:after,
.mfp-arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}
.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}
.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}
.mfp-arrow-left {
	left: 0;
}
.mfp-arrow-left:after {
	border-right: 17px solid #fff;
	margin-left: 31px;
}
.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3f3f3f;
}
.mfp-arrow-right {
	right: 0;
}
.mfp-arrow-right:after {
	border-left: 17px solid #fff;
	margin-left: 39px;
}
.mfp-arrow-right:before {
	border-left: 27px solid #3f3f3f;
}
.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
	top: -40px;
}
.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}
.mfp-figure {
	line-height: 0;
}
.mfp-figure:after {
	content: "";
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}
.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}
.mfp-figure figure {
	margin: 0;
}
.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}
.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-wrap: break-word;
	padding-right: 36px;
}
.mfp-image-holder .mfp-content {
	max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape),
	screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}
.main-article {
	float: left;
	width: 59.32203%;
}
.tim-thumbnail-outer img {
	width: 100%;
	height: auto;
}
.entry-header {
	padding: 20px 0;
}
.entry-header h1 {
	font-size: 27px;
	line-height: 1.3;
	font-weight: 400;
	padding: 30px 0 0;
	margin-bottom: 25px;
	position: relative;
	margin-top: 35px;
}
.entry-header h1::after {
	display: block;
	content: "";
	width: 60px;
	height: 2px;
	background: #3fa9f5;
	position: absolute;
	top: 0;
	left: 0;
}
.entry-header .breadcrumbs {
	font-weight: 300;
	color: #7b8086;
	margin-bottom: 3.58333em;
}
.entry-header .breadcrumbs a {
	color: inherit;
}
.entry-header .entry-meta {
	font-size: 14px;
	color: #aeb0b3;
	padding-bottom: 20px;
}
.entry-header .entry-meta a {
	color: #7b8086;
}
.entry-content .more-link {
	white-space: nowrap;
}
.entry-content h1 {
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 40px;
}
.entry-content ol + h2,
.entry-content ol + h3,
.entry-content p + h1,
.entry-content p + h2,
.entry-content p + h3,
.entry-content ul + h2,
.entry-content ul + h3 {
	padding-top: 1.666666666667em;
}
.entry-content strong {
	font-weight: 700;
}
.entry-content em {
	font-style: italic;
}
.entry-content blockquote {
	font-style: italic;
	margin-bottom: 1.666666666667em;
	position: relative;
	padding: 45px 35px;
}
.entry-content blockquote:last-child {
	margin-bottom: 0;
}
.entry-content blockquote:after,
.entry-content blockquote:before {
	content: "";
	display: block;
	width: 87px;
	height: 76px;
	background: center no-repeat;
	position: absolute;
}
.entry-content blockquote:before {
	top: 0;
	left: 0;
	background-image: url(../assets/images/bg-blockquote-blog-open.png);
}
.entry-content blockquote:after {
	right: 0;
	bottom: 0;
	background-image: url(../assets/images/bg-blockquote-blog-close.png);
}
.entry-content ol,
.entry-content p,
.entry-content ul {
	margin-bottom: 1.666666666667em;
}
.entry-content li {
	margin-bottom: 0.8333333333335em;
}
.entry-content h1:last-child .entry-content h2:last-child,
.entry-content h3:last-child,
.entry-content li:last-child,
.entry-content ol:last-child,
.entry-content p:last-child,
.entry-content ul:last-child {
	margin-bottom: 0;
}
.entry-content ol,
.entry-content ul {
	margin-left: 1.388888888889em;
}
.entry-content ul li {
	list-style: none;
	position: relative;
	padding: 0 0 0 33px;
}
.entry-content ul li:before {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	background: #3fa9f5;
	position: absolute;
	top: 0.8333333333335em;
	left: 0;
	margin-top: -4px;
}
.entry-content ul li .alignleft,
.entry-content ul li .alignright,
.entry-content ul li img {
	margin-bottom: 5px;
}
.entry-content ol li {
	list-style: decimal;
}
.entry-content li ol,
.entry-content li ul {
	margin-top: 0.8333333333335em;
}
.entry-content .wp-caption {
	margin-bottom: 1.666666666667em;
}
.entry-content .wp-caption img {
	display: block;
	margin-bottom: 0.8333333333335em;
}
.entry-content .wp-caption-text {
	font-style: italic;
}
.entry-content img.aligncenter {
	margin: 0 auto;
	display: block;
}
.entry-content img.alignone {
	display: block;
}
.entry-content div.aligncenter {
	margin: 0 auto;
}
.entry-content div.aligncenter img,
.entry-content div.alignone img {
	display: block;
}
.entry-content .alignleft {
	float: left;
	margin: 0.4166666666668em 1.666666666667em 1.666666666667em 0;
}
.main-article .entry-content .align-sc {
	margin-bottom: 1.3em;
}
.entry-content .alignright {
	float: right;
	margin: 0.4166666666668em 0 1.666666666667em 1.666666666667em;
}
.entry-content iframe {
	max-width: 100%;
}
.post-thumbnail {
	background: #e9e9e9;
	margin-bottom: 30px;
}
.post-thumbnail img {
	width: 100%;
	height: auto;
}
.entry-footer {
	overflow: hidden;
	font-size: 17px;
	font-size: 0.85rem;
	line-height: 1.6em;
	font-weight: 600;
	font-style: italic;
}
.entry-footer p {
	padding-bottom: 0 !important;
}
.entry-footer .comments {
	float: left;
}
.entry-footer a {
	color: #3f4a4d;
	text-decoration: underline;
}
.entry-footer a:hover {
	text-decoration: none;
}
.entry-footer .tweet {
	float: right;
}
.entry-footer .tweet iframe {
	margin: 3px 0 0 0;
}
@media only screen and (max-width: 960px) {
	.main-article {
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
	}
	.entry-header .title {
		font-size: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.entry-content {
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
	}
}
@media only screen and (max-width: 479px) {
	.entry-header .title {
		font-size: 27px;
	}
	.entry-content h2,
	.entry-content h3 {
		font-size: 20px;
	}
}
.np-testimonial {
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.np-testimonial .border {
	padding-top: 17px;
}
.np-testimonial .border:after {
	top: 0;
	left: 50%;
	margin-left: -40px;
}
.np-testimonial-text {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
	font-size: 18px;
	line-height: 1.66667;
	margin-bottom: 1.125em;
	letter-spacing: 0.025em;
	quotes: "“" "”" "‘" "’";
}
.np-testimonial-text:before {
	content: open-quote;
}
.np-testimonial-text:after {
	content: close-quote;
}
.np-testimonial-title {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
	font-size: 16px;
	line-height: 1.66667;
	font-weight: 600;
	padding-top: 1.25em;
}
.np-testimonial-img {
	display: inline-block;
}
.main-aside {
	max-width: 700px;
	margin: 0 auto;
}
@media (min-width: 960px) {
	.main-aside {
		float: right;
		width: 33.05085%;
	}
}
.widget {
	background: #f6fbff;
	margin-bottom: 60px;
	margin-top: 40px;
}
.widget:last-child {
	margin-bottom: 0;
}
.widget input::-webkit-input-placeholder {
	color: inherit;
}
.widget input::-moz-placeholder {
	color: inherit;
}
.widget input::-ms-input-placeholder {
	color: inherit;
}
.widget input::placeholder {
	color: inherit;
}
.widget input:focus::-webkit-input-placeholder {
	color: #e4e8ed;
}
.widget input:focus::-moz-placeholder {
	color: #e4e8ed;
}
.widget input:focus::-ms-input-placeholder {
	color: #e4e8ed;
}
.widget input:focus::placeholder {
	color: #e4e8ed;
}
.widget .btn-wrap {
	text-align: center;
}
.widget .btn {
	padding: 23px 20px;
	min-width: 225px;
	max-width: 100%;
	background-color: #ff4500;
	border-color: #ff4500;
}
.widget .btn:hover {
	background-color: #fff;
	color: #ff4500;
}
.widget-default {
	text-align: center;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-default {
		padding: 35px 40px 60px 40px;
	}
}
.widget-default .title {
	padding-top: 0.58333em;
	margin-bottom: 0.75em;
	font-size: 26px;
	line-height: 1.6;
}
.widget-default .title.border {
	padding-bottom: 20px;
	margin-bottom: 25px;
}
.widget-default .title.border:after {
	width: 40px;
	margin-left: -20px;
	left: 50%;
	height: 2px;
}
.widget-default p {
	margin-bottom: 15px;
}
@media (min-width: 1200px) {
	.widget-default .tim-widget-description,
	.widget-default .title {
		margin-right: auto;
		margin-left: auto;
	}
}
.widget-default .description {
	margin-bottom: 30px;
}
.widget-default .tim-sidebar-form {
	padding-top: 20px;
}
.widget-default .tim-sidebar-form input[type="text"] {
	width: 225px;
	max-width: 100%;
}
.widget-default .tim-sidebar-form .form-submit {
	margin: 40px 0;
}
.widget-about {
	border: solid #e9e9e9 1px;
	font-size: 14px;
	line-height: 1.71429;
	letter-spacing: 0.025em;
}
.widget-about .img-wrap {
	border-bottom: solid #e9e9e9 1px;
	padding-top: 10px;
}
.widget-about .img {
	margin: 0 auto;
}
.widget-about .text {
	padding: 20px 10px;
	color: #707378;
}
@media (min-width: 480px) {
	.widget-about .text {
		padding: 35px 40px 30px 40px;
	}
}
.widget-about p {
	margin-bottom: 1.71429em;
}
.widget-about p:last-child {
	margin-bottom: 0;
}
.widget-about .title {
	padding-top: 0.63636em;
	margin-bottom: 0.63636em;
	font-weight: 700;
	color: #292b2c;
}
.widget-about .title:after {
	width: 40px;
	height: 2px;
	top: 0;
}
.widget-search {
	position: relative;
}
.widget-search .form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	border: solid #e4e8ed 1px;
	padding: 0 10px;
	border-radius: 2px;
}
@media (min-width: 480px) {
	.widget-search .form {
		padding: 0 35px 0 40px;
	}
}
.widget-search .submit,
.widget-search .text {
	padding: 0;
}
.widget-search .text {
	padding-right: 10px;
}
@media (min-width: 480px) {
	.widget-search .text {
		padding-right: 35px;
	}
}
.widget-search .submit {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 28px;
	height: 28px;
	background: url(../assets/images/bg-search.svg) right center no-repeat;
	cursor: pointer;
}
.widget-help {
	text-align: center;
}
.widget-help .title-wrap {
	background: #3fa9f5;
	color: #fff;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-help .title-wrap {
		padding: 50px 30px 45px 30px;
	}
}
.widget-help .title {
	padding-top: 0.61111em;
	font-weight: 600;
}
.widget-help .border:after {
	top: 0;
	left: 50%;
	height: 2px;
	background: #fff;
	width: 40px;
	margin-left: -20px;
}
.widget-testimonial {
	background: #3fa9f5;
	color: #fff;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-testimonial {
		padding: 90px 40px 100px 40px;
	}
}
.widget-testimonial .border:after {
	background: #fff;
}
@media (min-width: 768px) {
	.widget-testimonial .np-testimonial-text {
		font-size: 20px;
		line-height: 2.2;
	}
}
.widget-testimonial .np-testimonial-title {
	color: #e4e8ed;
	margin-bottom: 2em;
}
.widget-testimonial .btn-wrap {
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
}
.widget-optin {
	text-align: center;
	border: solid #e9e9e9 1px;
	padding: 35px 40px;
	font-size: 14px;
	line-height: 1.714285714286em;
	letter-spacing: 0.25px;
}
.widget-optin .title {
	font-size: 28px;
	line-height: 1.285714285714em;
	font-weight: 600;
	margin: 0 auto 0.5em auto;
	max-width: 320px;
}
.widget-optin .np-form {
	margin-bottom: 0;
}
.widget-optin ul {
	text-align: left;
	padding-top: 1em;
}
.widget-optin li {
	list-style: none;
	position: relative;
	padding: 0 0 0 20px;
	margin: 0 0 12px 0;
	color: #e9e9e9;
}
.widget-optin li:last-child {
	margin-bottom: 0;
}
.widget-optin li:before {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	background: #e9e9e9;
	position: absolute;
	top: 0.875em;
	left: 0;
	margin-top: -3px;
}
@media (min-width: 768px) {
	.widget-best-content .btn-group-lg {
		margin: 0 -40px;
	}
}
.widget-best-content .btn-group-lg .btn {
	min-width: 107px;
}
.widget-best-content .tab-content-area {
	width: 100%;
}
.widget-best-content .tab-content-area li {
	width: 100%;
	clear: both;
	overflow: hidden;
	padding: 15px 0;
	text-align: left;
}
.widget-best-content .tab-content-area li img {
	float: left;
	margin: 0 15px 10px 0;
	font-size: 3px;
	line-height: 1;
}
.widget-best-content .tab-content-area li a {
	color: #292b2c;
}
.tim-widget-altucher {
	padding: 10px 10px 0 10px;
}
.np-domain-check {
	padding: 30px;
	background: #f5f5f5;
	border: solid #e9e9e9 1px;
	position: relative;
	margin-bottom: 1.666666666667em;
}
.np-domain-check .title {
	margin-bottom: 1em;
}
.np-domain-check .cols {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.np-domain-check .cols .field-p-name {
	padding-left: 0;
	width: 65%;
}
.np-domain-check .cols .field-p-submit,
.np-domain-check .cols .field-p-tld {
	padding-right: 0;
	width: 35%;
}
.np-domain-check .cols .btn {
	padding-top: 19px;
	padding-bottom: 19px;
	width: 100%;
	display: block;
}
.np-domain-check .field-p {
	display: table-cell;
	vertical-align: middle;
	padding: 0 10px;
	width: 50%;
}
.np-domain-check .field-p-submit {
	margin-bottom: 0;
}
.np-domain-check .info-list {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 20px;
	-moz-column-gap: 20px;
	column-gap: 20px;
}
.np-domain-check .info-list a {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
}
.np-domain-check .submit {
	width: 100%;
	padding: 10px 15px;
	font-size: 16px;
	line-height: 1.75em;
	margin-bottom: 0;
}
.np-domain-check .info {
	padding-top: 1.666666666667em;
	display: none;
}
.np-keyword-check .cols {
	margin-bottom: 0;
}
@media only screen and (max-width: 479px) {
	.np-domain-check {
		padding: 20px 10px;
	}
	.np-domain-check .cols,
	.np-domain-check .cols .field-p {
		display: block;
		margin-bottom: 10px;
	}
	.np-keyword-check .cols {
		margin-bottom: 0;
	}
	.np-domain-check .cols .field-p-name,
	.np-domain-check .cols .field-p-submit,
	.np-domain-check .cols .field-p-tld {
		width: 100%;
	}
	.np-domain-check .cols .btn {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.np-domain-check .cols .field-p:last-child {
		margin-bottom: 0;
	}
	.np-domain-check .field-p {
		padding: 0;
	}
	.np-domain-check .info-list {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		-moz-column-gap: 0;
		column-gap: 0;
	}
}
.site-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 6px;
}
.site-logo svg {
	position: relative;
	width: 186px !important;
	height: 24px !important;
}
.site-logo .menu-item {
	font-size: 12px;
	line-height: 1.66667;
	margin: 0 0 0 19px;
	position: relative;
}
.site-logo .sub-menu {
	right: 0;
	left: initial;
}
@media (min-width: 480px) {
	.site-logo .sub-menu {
		left: 21px;
		right: initial;
	}
}
.site-logo .lang {
	display: block;
	position: relative;
	padding: 3px 23px 3px 21px;
}
.site-logo .lang:after,
.site-logo .lang:before {
	content: "";
	position: absolute;
}
.site-logo .lang:before {
	width: 1px;
	height: 100%;
	top: 0;
	left: 0;
	background: #fff;
	opacity: 0.2;
}
.site-logo .lang:after {
	top: 50%;
	right: 2px;
	width: 7px;
	height: 7px;
	margin-top: -5px;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	-webkit-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0.5;
}
.site-logo .lang:hover:after {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.menu li {
	float: left;
}
.tim-top-bar .menu a {
	padding: 5px 10px;
}
.site-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-flow: wrap;
	flex-flow: wrap;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@media (min-width: 1200px) {
	.site-menu .menu-item {
		margin-left: 45px;
	}
}
.site-menu .menu-item.btn-wrap-wrw a {
	border-color: #fff;
}
.site-menu .current-menu-item a {
	border-bottom: solid #c6f1ff 1px;
}
.site-menu .menu-item-has-children a:hover {
	border-color: #3fa9f5;
}

.menu-item:hover .sub-menu {
	opacity: 1;
	visibility: visible;
}
.sub-menu .menu-item {
	margin: 0;
	font-size: 12px;
	line-height: 1.66667;
	width: 100%;
}
.sub-menu .menu-item a {
	display: block;
	color: #292b2c;
	padding: 5px 30px;
	border: 0;
}
.sub-menu .menu-item a:hover {
	color: #3fa9f5;
	border: 0;
}
.sub-menu .menu-item-active a {
	color: #aeb0b3;
}

.site-footer a {
	text-decoration: none;
	color: #fff;
	font-weight: 500;
	text-decoration: none;
	color: #fff;
	font-weight: 500;
}
.site-footer p {
	font-size: 16px;
	font-size: 16px;
}
.site-footer ul {
	font-size: 16px;
	font-size: 16px;
}
.site-footer .logo-menu {
	margin-bottom: 32px;
	margin-bottom: 32px;
}
.site-footer .title {
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
}
.site-footer .site-logo {
	margin-bottom: 25px;
	margin-bottom: 25px;
}
.site-footer .site-menu {
	letter-spacing: 0.025em;
	letter-spacing: 0.025em;
}
.site-footer .site-menu > .menu-item-has-children {
	letter-spacing: 0;
	letter-spacing: 0;
}
.site-footer .site-menu a {
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
}
.site-footer .site-menu .btn-wrap-s {
	display: inline-block;
	width: 100%;
	display: inline-block;
	width: 100%;
}
.site-footer .site-menu .btn-wrap-s a {
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
}
.site-footer .about-contact-links {
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
}
.site-footer .footer-site-menu {
	padding: 35px 0;
	padding: 35px 0;
}
.site-footer .footer-site-menu .site-menu .menu-item {
	display: block;
	width: 50%;
	display: block;
	width: 50%;
}
.site-footer .footer-site-menu .site-menu .menu-item a {
	font-size: 15px;
	font-size: 15px;
}
.site-footer .tim-box-about {
	text-align: left;
	text-align: left;
}
.site-footer .tim-box-about img {
	float: left;
	margin: -10px 25px 15px 0;
	float: left;
	margin: -10px 25px 15px 0;
}
.site-footer .footer-list li {
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
}
.site-footer .footer-list li a {
	border-bottom: 1px solid #9fd4fa;
	border-bottom: 1px solid #9fd4fa;
}
.site-footer .copyright-links {
	background: #111111;
	padding: 40px 0;
	font-size: 12px;
	letter-spacing: 0.05em;
	padding: 40px 0;
	font-size: 12px;
}
.site-footer .copyright-links p {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .copyright-links ul {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .copyright-links .copy {
	margin-bottom: 5px;
	margin-bottom: 5px;
}
.site-footer .copyright-links .copy-url {
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
}
.site-footer .copyright-links .copy-url:hover {
	border-color: #fff;
	border-color: #fff;
}
.site-footer .copyright-links .menu-bottom li {
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
}
.site-footer .copyright-links .menu-bottom li a {
	font-size: 12px;
	font-size: 12px;
}
.site-footer .sub-menu {
	top: initial;
	bottom: 100%;
	top: initial;
	bottom: 100%;
}
.site-header {
	background: #3fa9f5;
	color: #fff;
	clear: both;
	padding: 28px 0 28px 0;
	font-size: 15px;
	line-height: 1.66667;
	background: #3fa9f5;
	color: #fff;
	clear: both;
	padding: 28px 0 28px 0;
	font-size: 15px;
	line-height: 1.66667;
}
.site-header a {
	text-decoration: none;
	color: #fff;
	text-decoration: none;
	color: #fff;
}
.site-header .inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.site-header .site-nav {
	display: none;
	display: none;
}
.site-header .hide {
	display: none;
	display: none;
}
.site-header .toggle {
	display: inline-block;
	cursor: pointer;
	width: 28px;
	height: 21px;
	border-top: solid #fff 3px;
	position: relative;
	margin: 2px 0 3px 0;
	display: inline-block;
	cursor: pointer;
	width: 28px;
	height: 21px;
	border-top: solid #fff 3px;
	position: relative;
	margin: 2px 0 3px 0;
}
.site-header .toggle:after {
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
}
.site-header .toggle:before {
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	top: 6px;
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background: #fff;
	position: absolute;
	left: 0;
	top: 6px;
}
#nav-toggle {
	display: none;
}
#nav-toggle:checked + .site-header {
	background: #fff;
	color: #292b2c;
	height: 100vh;
	overflow: auto;
}
#nav-toggle:checked + .site-header .svg-path {
	fill: #292b2c !important;
}
#nav-toggle:checked + .site-header:hover .svg-path {
	fill: #292b2c !important;
}
#nav-toggle:checked + .site-header .site-logo .svg {
	width: 186px !important;
	height: 24px !important;
}
#nav-toggle:checked + .site-header .site-logo .menu-item {
	display: none;
}
#nav-toggle:checked + .site-header .inner {
	-ms-flex-flow: wrap;
	flex-flow: wrap;
}
#nav-toggle:checked + .site-header .site-nav {
	display: block;
	width: 100%;
}
#nav-toggle:checked + .site-header .site-menu {
	margin-top: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
#nav-toggle:checked + .site-header .site-menu .menu-item {
	margin-left: 0;
	width: 100%;
	text-align: center;
}
#nav-toggle:checked + .site-header .site-menu a {
	color: #292b2c;
	border: 0;
	display: block;
	padding: 10px 0;
	font-size: 24px;
	line-height: 1.41667;
	font-weight: 400;
}
#nav-toggle:checked + .site-header .site-menu a:hover {
	color: #3fa9f5 !important;
}
#nav-toggle:checked + .site-header .site-menu .menu-item-active > a {
	color: #aeb0b3;
}
#nav-toggle:checked + .site-header .btn-wrap a {
	text-transform: initial;
	letter-spacing: initial;
	background: initial;
	border: 0;
}
#nav-toggle:checked + .site-header .sub-menu {
	all: unset;
}
#nav-toggle:checked + .site-header .sub-menu a {
	font-size: 18px;
	line-height: 1.66667;
	font-weight: 300;
}
#nav-toggle:checked + .site-header .toggle:hover:after,
#nav-toggle:checked + .site-header .toggle:hover:before {
	background: #292b2c;
}
#nav-toggle:checked + .site-header .toggle:after,
#nav-toggle:checked + .site-header .toggle:before {
	background: #292b2c;
}
#nav-toggle:checked + .site-header .toggle:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
#nav-toggle:checked + .site-header .toggle:after {
	top: 6px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.site-footer {
	padding: 35px 0 0 0;
	font-size: 12px;
	line-height: 1.66667;
	text-align: center;
}
.site-footer p,
.site-footer ul {
	font-size: 16px;
}
.site-footer a {
	font-weight: 500;
}
@media (min-width: 768px) {
	.site-footer {
		text-align: left;
	}
}
.site-footer .logo-menu {
	margin-bottom: 32px;
}
@media (min-width: 480px) {
	.site-footer .logo-menu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
@media (min-width: 768px) {
	.site-footer .logo-menu {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}
.site-footer .title {
	font-size: 26px;
	line-height: 1.4;
	margin: 40px 0 55px 0;
	font-weight: 300;
	font-family: Fira Sans;
	padding: 0;
}
.site-footer .site-logo {
	margin-bottom: 25px;
}
@media (min-width: 480px) {
	.site-footer .site-logo {
		margin-bottom: 0;
	}
}
.site-footer .site-menu {
	letter-spacing: 0.025em;
}

.site-footer .site-menu a {
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom-color: transparent;
	letter-spacing: 0.7px;
}
.site-footer .site-menu .btn-wrap-s {
	display: inline-block;
	width: 100%;
}
@media (min-width: 480px) {
	.site-footer .site-menu .btn-wrap-s {
		display: block;
		width: auto;
	}
}
.site-footer .site-menu .btn-wrap-s a {
	font-size: 10px;
	line-height: 1.66667;
	padding-top: 17px;
	padding-bottom: 15px;
	letter-spacing: 0.16em;
}
.site-footer .about-contact-links {
	border-bottom: 2px solid #5bb7f7;
	padding-top: 20px;
	padding-bottom: 65px;
}
.site-footer .footer-site-menu {
	padding: 35px 0;
}
.site-footer .tim-box-about {
	text-align: left;
}
@media (min-width: 1200px) {
	.site-footer .tim-box-about {
		width: 70%;
	}
}
.site-footer .tim-box-about img {
	float: left;
	margin: -10px 25px 15px 0;
}
.site-footer .footer-list li {
	background: url(../assets/images/check-circle-07.svg) no-repeat;
	padding-left: 40px;
	margin-bottom: 15px;
	text-align: left;
}
.site-footer .footer-list li a {
	border-bottom: 1px solid #9fd4fa;
}
.site-footer .copyright-links {
	background: #111111;
	letter-spacing: 0.05em;
	padding: 40px 0;
	font-size: 12px;
}
.site-footer .copyright-links p,
.site-footer .copyright-links ul {
	font-size: 12px;
}
@media (min-width: 768px) {
	.site-footer .copyright-links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.site-footer .copyright-links .copy {
	margin-bottom: 5px;
}
@media (min-width: 768px) {
	.site-footer .copyright-links .copy {
		margin-bottom: 0;
	}
}
.site-footer .copyright-links .copy-url {
	display: inline-block;
	padding: 4px 0;
	border-bottom: solid #3fa9f5 1px;
}
.site-footer .copyright-links .copy-url:hover {
	border-color: #fff;
}
.site-footer .copyright-links .menu-bottom li {
	width: 50%;
	text-align: left;
	float: left;
	margin: 0;
}
.site-footer .copyright-links .menu-bottom li a {
	font-size: 12px;
}
.site-footer .sub-menu {
	top: initial;
	bottom: 100%;
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
	.col-sm-1 {
		float: left;
		width: 8.33333%;
	}
	.col-sm-10 {
		float: left;
		width: 83.33333%;
	}
	.col-sm-11 {
		float: left;
		width: 91.66667%;
	}
	.col-sm-12 {
		float: left;
		width: 100%;
	}
	.col-sm-2 {
		float: left;
		width: 16.66667%;
	}
	.col-sm-3 {
		float: left;
		width: 25%;
	}
	.col-sm-4 {
		float: left;
		width: 33.33333%;
	}
	.col-sm-5 {
		float: left;
		width: 41.66667%;
	}
	.col-sm-6 {
		float: left;
		width: 50%;
	}
	.col-sm-7 {
		float: left;
		width: 58.33333%;
	}
	.col-sm-8 {
		float: left;
		width: 66.66667%;
	}
	.col-sm-9 {
		float: left;
		width: 75%;
	}
	.col-sm-pull-0 {
		right: auto;
	}
	.col-sm-pull-1 {
		right: 8.33333%;
	}
	.col-sm-pull-2 {
		right: 16.66667%;
	}
	.col-sm-pull-3 {
		right: 25%;
	}
	.col-sm-pull-4 {
		right: 33.33333%;
	}
	.col-sm-pull-5 {
		right: 41.66667%;
	}
	.col-sm-pull-6 {
		right: 50%;
	}
	.col-sm-pull-7 {
		right: 58.33333%;
	}
	.col-sm-pull-8 {
		right: 66.66667%;
	}
	.col-sm-pull-9 {
		right: 75%;
	}
	.col-sm-pull-10 {
		right: 83.33333%;
	}
	.col-sm-pull-11 {
		right: 91.66667%;
	}
	.col-sm-pull-12 {
		right: 100%;
	}
	.col-sm-push-0 {
		left: auto;
	}
	.col-sm-push-1 {
		left: 8.33333%;
	}
	.col-sm-push-2 {
		left: 16.66667%;
	}
	.col-sm-push-3 {
		left: 25%;
	}
	.col-sm-push-4 {
		left: 33.33333%;
	}
	.col-sm-push-5 {
		left: 41.66667%;
	}
	.col-sm-push-6 {
		left: 50%;
	}
	.col-sm-push-7 {
		left: 58.33333%;
	}
	.col-sm-push-8 {
		left: 66.66667%;
	}
	.col-sm-push-9 {
		left: 75%;
	}
	.col-sm-push-10 {
		left: 83.33333%;
	}
	.col-sm-push-11 {
		left: 91.66667%;
	}
	.col-sm-push-12 {
		left: 100%;
	}
	.col-sm-offset-0 {
		margin-left: 0;
	}
	.col-sm-offset-1 {
		margin-left: 8.33333%;
	}
	.col-sm-offset-2 {
		margin-left: 16.66667%;
	}
	.col-sm-offset-3 {
		margin-left: 25%;
	}
	.col-sm-offset-4 {
		margin-left: 33.33333%;
	}
	.col-sm-offset-5 {
		margin-left: 41.66667%;
	}
	.col-sm-offset-6 {
		margin-left: 50%;
	}
	.col-sm-offset-7 {
		margin-left: 58.33333%;
	}
	.col-sm-offset-8 {
		margin-left: 66.66667%;
	}
	.col-sm-offset-9 {
		margin-left: 75%;
	}
	.col-sm-offset-10 {
		margin-left: 83.33333%;
	}
	.col-sm-offset-11 {
		margin-left: 91.66667%;
	}
	.col-sm-offset-12 {
		margin-left: 100%;
	}
	.border:after {
		left: 47.4%;
	}
	.tim-pull-right {
		float: right !important;
	}
	.width-90-center {
		width: 90%;
		margin: 0 auto;
	}
	.width-80-center {
		width: 80%;
		margin: 0 auto;
	}
	.width-70-center {
		width: 70%;
		margin: 0 auto;
	}
	.width-65-center {
		width: 65%;
		margin: 0 auto;
	}
	.width-60-center {
		width: 60%;
		margin: 0 auto;
	}
	.width-55-center {
		width: 55%;
		margin: 0 auto;
	}
	.tim-img-md-screen {
		display: block !important;
	}
	.tim-img-small-screen {
		display: none !important;
	}
	.size-20 {
		font-size: 20px;
		line-height: 1.8;
	}
	.size-22 {
		font-size: 22px;
		line-height: 1.63636;
	}
	.size-24 {
		font-size: 24px;
		line-height: 1.41667;
	}
	.size-34 {
		font-size: 34px;
		line-height: 1.17647;
	}
	.size-36 {
		font-size: 36px;
		line-height: 1.02778;
	}
	.size-40 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-48 {
		font-size: 48px;
		line-height: 1.292857143;
	}
	.btn-wrap a.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn-wrap a:visited.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn:visited.btn-large {
		padding: 23px 50px;
		font-size: 19px;
	}
	.btn-group-lg li {
		margin: 0;
	}
	.btn-group-lg li .btn {
		min-width: 195px;
		border-right: none;
		border-radius: 0;
	}
	.btn-group-lg li:first-child .btn {
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}
	.btn-group-lg li.btn {
		min-width: 195px;
		border-right: none;
		border-radius: 0;
	}
	.nice-select.wide {
		height: 75px;
	}
	.nice-select.wide span {
		padding: 17px 25px 0 40px;
		font-size: 34px;
		line-height: 38px;
	}
	.wpcf7-submit {
		width: auto !important;
	}
	h2 {
		font-size: 50px;
		font-size: 50px;
	}
	h3 {
		font-size: 35px;
		font-size: 35px;
	}
	.tim-divider {
		padding-top: 80px;
		margin-bottom: 50px;
		padding-top: 80px;
		margin-bottom: 50px;
	}
	.tim-divider:after {
		left: 47.4%;
		left: 47.4%;
	}
	.tim-video-decorator {
		padding: 5px 50px 0 50px;
		display: inline-block;
		border-radius: 2px;
		max-width: none;
		padding: 5px 50px 0 50px;
		display: inline-block;
		border-radius: 2px;
		max-width: none;
	}
	.tim-video-decorator:before {
		background: #c6f1ff;
		content: "";
		display: block;
		top: 35px;
		left: 0;
		right: 0;
		bottom: 30px;
		position: absolute;
		z-index: -1;
		border-radius: 2px;
		background: #c6f1ff;
		content: "";
		display: block;
		top: 35px;
		left: 0;
		right: 0;
		bottom: 30px;
		position: absolute;
		z-index: -1;
		border-radius: 2px;
	}
	.site-header .site-nav {
		display: block;
		display: block;
	}
	.site-header .toggle {
		display: none;
		display: none;
	}
	.site-footer {
		text-align: left;
		text-align: left;
	}
	.site-footer .logo-menu {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.site-footer .site-menu > .menu-item {
		display: block;
		display: block;
	}
	.site-footer .copyright-links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.site-footer .copyright-links .copy {
		margin-bottom: 0;
		margin-bottom: 0;
	}

	.entry-header h1 {
		font-size: 38px;
		font-size: 38px;
	}
	.widget-testimonial .np-testimonial-text {
		font-size: 20px;
		line-height: 2.2;
		font-size: 20px;
		line-height: 2.2;
	}
	.widget-best-content .btn-group-lg {
		margin: 0 -40px;
		margin: 0 -40px;
	}
	.entry-share .title {
		display: block;
		font-weight: 400;
	}
	.entry-share .share-list .share:first-child {
		margin-left: 35px;
	}
	.page-opting-in-template .tim-video-decorator {
		margin-left: 0;
		margin-right: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.page-opting-in-template .tim-video-decorator .tim-video-decorator-inside {
		height: 430px;
		margin-left: 0;
		margin-right: 0;
	}
	.page-opting-in-template .menu li {
		width: 33%;
		float: left;
		padding: 10px;
	}
	.tim-page-about {
		padding: 90px 0 100px;
	}
	.tim-page-about .title {
		font-size: 47px;
	}
	.tim-page-about .title strong:before {
		bottom: 10px;
	}
	.tim-page-about .title.border {
		padding-bottom: 32px;
		margin-bottom: 35px;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
	.col-md-1 {
		float: left;
		width: 8.33333%;
	}
	.col-md-10 {
		float: left;
		width: 83.33333%;
	}
	.col-md-11 {
		float: left;
		width: 91.66667%;
	}
	.col-md-12 {
		float: left;
		width: 100%;
	}
	.col-md-2 {
		float: left;
		width: 16.66667%;
	}
	.col-md-3 {
		float: left;
		width: 25%;
	}
	.col-md-4 {
		float: left;
		width: 33.33333%;
	}
	.col-md-5 {
		float: left;
		width: 41.66667%;
	}
	.col-md-6 {
		float: left;
		width: 50%;
	}
	.col-md-7 {
		float: left;
		width: 58.33333%;
	}
	.col-md-8 {
		float: left;
		width: 66.66667%;
	}
	.col-md-9 {
		float: left;
		width: 75%;
	}
	.col-md-pull-0 {
		right: auto;
	}
	.col-md-pull-1 {
		right: 8.33333%;
	}
	.col-md-pull-2 {
		right: 16.66667%;
	}
	.col-md-pull-3 {
		right: 25%;
	}
	.col-md-pull-4 {
		right: 33.33333%;
	}
	.col-md-pull-5 {
		right: 41.66667%;
	}
	.col-md-pull-6 {
		right: 50%;
	}
	.col-md-pull-7 {
		right: 58.33333%;
	}
	.col-md-pull-8 {
		right: 66.66667%;
	}
	.col-md-pull-9 {
		right: 75%;
	}
	.col-md-pull-10 {
		right: 83.33333%;
	}
	.col-md-pull-11 {
		right: 91.66667%;
	}
	.col-md-pull-12 {
		right: 100%;
	}
	.col-md-push-0 {
		left: auto;
	}
	.col-md-push-1 {
		left: 8.33333%;
	}
	.col-md-push-2 {
		left: 16.66667%;
	}
	.col-md-push-3 {
		left: 25%;
	}
	.col-md-push-4 {
		left: 33.33333%;
	}
	.col-md-push-5 {
		left: 41.66667%;
	}
	.col-md-push-6 {
		left: 50%;
	}
	.col-md-push-7 {
		left: 58.33333%;
	}
	.col-md-push-8 {
		left: 66.66667%;
	}
	.col-md-push-9 {
		left: 75%;
	}
	.col-md-push-10 {
		left: 83.33333%;
	}
	.col-md-push-11 {
		left: 91.66667%;
	}
	.col-md-push-12 {
		left: 100%;
	}
	.col-md-offset-0 {
		margin-left: 0;
	}
	.col-md-offset-1 {
		margin-left: 8.33333%;
	}
	.col-md-offset-2 {
		margin-left: 16.66667%;
	}
	.col-md-offset-3 {
		margin-left: 25%;
	}
	.col-md-offset-4 {
		margin-left: 33.33333%;
	}
	.col-md-offset-5 {
		margin-left: 41.66667%;
	}
	.col-md-offset-6 {
		margin-left: 50%;
	}
	.col-md-offset-7 {
		margin-left: 58.33333%;
	}
	.col-md-offset-8 {
		margin-left: 66.66667%;
	}
	.col-md-offset-9 {
		margin-left: 75%;
	}
	.col-md-offset-10 {
		margin-left: 83.33333%;
	}
	.col-md-offset-11 {
		margin-left: 91.66667%;
	}
	.col-md-offset-12 {
		margin-left: 100%;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	.col-lg-1 {
		float: left;
		width: 8.33333%;
	}
	.col-lg-10 {
		float: left;
		width: 83.33333%;
	}
	.col-lg-11 {
		float: left;
		width: 91.66667%;
	}
	.col-lg-12 {
		float: left;
		width: 100%;
	}
	.col-lg-2 {
		float: left;
		width: 16.66667%;
	}
	.col-lg-3 {
		float: left;
		width: 25%;
	}
	.col-lg-4 {
		float: left;
		width: 33.33333%;
	}
	.col-lg-5 {
		float: left;
		width: 41.66667%;
	}
	.col-lg-6 {
		float: left;
		width: 50%;
	}
	.col-lg-7 {
		float: left;
		width: 58.33333%;
	}
	.col-lg-8 {
		float: left;
		width: 66.66667%;
	}
	.col-lg-9 {
		float: left;
		width: 75%;
	}
	.col-lg-pull-0 {
		right: auto;
	}
	.col-lg-pull-1 {
		right: 8.33333%;
	}
	.col-lg-pull-2 {
		right: 16.66667%;
	}
	.col-lg-pull-3 {
		right: 25%;
	}
	.col-lg-pull-4 {
		right: 33.33333%;
	}
	.col-lg-pull-5 {
		right: 41.66667%;
	}
	.col-lg-pull-6 {
		right: 50%;
	}
	.col-lg-pull-7 {
		right: 58.33333%;
	}
	.col-lg-pull-8 {
		right: 66.66667%;
	}
	.col-lg-pull-9 {
		right: 75%;
	}
	.col-lg-pull-10 {
		right: 83.33333%;
	}
	.col-lg-pull-11 {
		right: 91.66667%;
	}
	.col-lg-pull-12 {
		right: 100%;
	}
	.col-lg-push-0 {
		left: auto;
	}
	.col-lg-push-1 {
		left: 8.33333%;
	}
	.col-lg-push-2 {
		left: 16.66667%;
	}
	.col-lg-push-3 {
		left: 25%;
	}
	.col-lg-push-4 {
		left: 33.33333%;
	}
	.col-lg-push-5 {
		left: 41.66667%;
	}
	.col-lg-push-6 {
		left: 50%;
	}
	.col-lg-push-7 {
		left: 58.33333%;
	}
	.col-lg-push-8 {
		left: 66.66667%;
	}
	.col-lg-push-9 {
		left: 75%;
	}
	.col-lg-push-10 {
		left: 83.33333%;
	}
	.col-lg-push-11 {
		left: 91.66667%;
	}
	.col-lg-push-12 {
		left: 100%;
	}
	.col-lg-offset-0 {
		margin-left: 0;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%;
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%;
	}
	.col-lg-offset-3 {
		margin-left: 25%;
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%;
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%;
	}
	.col-lg-offset-6 {
		margin-left: 50%;
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%;
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%;
	}
	.col-lg-offset-9 {
		margin-left: 75%;
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%;
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%;
	}
	.col-lg-offset-12 {
		margin-left: 100%;
	}
	.nl {
		display: block;
	}
	.size-56 {
		font-size: 56px;
		line-height: 1;
	}
	.size-84 {
		font-size: 84px;
		line-height: 1.11905;
	}

	.site-footer .footer-site-menu .site-menu .menu-item {
		width: auto;
		width: auto;
	}
	.site-footer .tim-box-about {
		width: 70%;
		width: 70%;
	}
	.widget-default .tim-widget-description {
		margin-right: auto;
		margin-left: auto;
		margin-right: auto;
		margin-left: auto;
	}
	.widget-default .title {
		margin-right: auto;
		margin-left: auto;
		margin-right: auto;
		margin-left: auto;
	}
	.page-content {
		padding: 0 40px 0 0;
	}
	.tim-sidebar-content {
		padding: 30px 0 0 20px;
	}
	.page-penny-stocking {
		padding-bottom: 50px;
	}
}
@media (max-width: 767px) {
	.col-50 {
		width: 100%;
	}
	.nice-select.wide:after {
		right: 3px;
	}
	.nice-select.wide span {
		height: 60px;
		padding-left: 0;
	}
	.nice-select.wide .list {
		top: 60px;
	}
	.transparent .nice-select:after {
		right: 3px;
	}
	.transparent .nice-select span {
		padding-left: 0;
	}
	.transparent input[type="submit"] {
		width: 100%;
	}
	.transparent .wpcf7-not-valid-tip {
		width: 100%;
		padding: 10px 0;
	}
	.transparent .wpcf7-response-output {
		padding: 10px;
	}
	.transparent.with-gray .nice-select:after {
		right: 3px;
	}
	.transparent.with-gray .nice-select span {
		padding-left: 0;
	}
	.transparent.with-gray input[type="submit"] {
		width: 100%;
	}
	.comment-form input {
		padding: 0 30px;
	}
	.comment-form .form-submit {
		width: 100%;
	}
	.form input {
		padding: 0 30px;
	}
	.form .form-submit {
		width: 100%;
	}
	.comment-form.two-columns fieldset {
		margin-bottom: 15px;
	}
	.comment-form.two-columns input {
		padding: 0;
	}
	.comment-form.two-columns input[type="submit"] {
		top: 15px;
		width: 100%;
	}
	.form.two-columns fieldset {
		margin-bottom: 15px;
	}
	.form.two-columns input {
		padding: 0;
	}
	.form.two-columns input[type="submit"] {
		top: 15px;
		width: 100%;
	}
	.form-lt {
		padding-top: 60px;
	}
	.form-lt h1 {
		margin-bottom: 57px;
		font-size: 28px;
		line-height: 28px;
	}
	.form-lt h1 span {
		font-size: 46px;
		line-height: 46px;
	}
	.tim-section-bg-mobile {
		background: #f6fbff !important;
		background: #f6fbff !important;
	}
	.tim-video-decorator iframe {
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@media (min-width: 480px) {
	.size-40 {
		font-size: 31px;
		line-height: 1.5;
	}
	.size-48 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-56 {
		font-size: 40px;
		line-height: 1.5;
	}
	.size-84 {
		font-size: 56px;
		line-height: 1;
	}
	.btn {
		min-width: 140px;
	}
	.btn:visited {
		min-width: 140px;
	}
	.btn-wrap a {
		min-width: 140px;
	}
	.btn-wrap a:visited {
		min-width: 140px;
	}
	input[type="submit"] {
		min-width: 140px;
	}
	.site-logo .sub-menu {
		left: 21px;
		right: initial;
		left: 21px;
		right: initial;
	}
	.site-footer .logo-menu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.site-footer .site-logo {
		margin-bottom: 0;
		margin-bottom: 0;
	}

	.site-footer .site-menu .btn-wrap-s {
		display: block;
		width: auto;
		display: block;
		width: auto;
	}

	.page-header .form-analyze .form-text {
		text-align: left;
		margin-bottom: 0;
		background: 0 0;
	}

	.widget-default {
		padding: 35px 40px 60px 40px;
		padding: 35px 40px 60px 40px;
	}
	.widget-about .text {
		padding: 35px 40px 30px 40px;
		padding: 35px 40px 30px 40px;
	}
	.widget-search .form {
		padding: 0 35px 0 40px;
		padding: 0 35px 0 40px;
	}
	.widget-search .text {
		padding-right: 35px;
		padding-right: 35px;
	}
	.widget-help .title-wrap {
		padding: 50px 30px 45px 30px;
		padding: 50px 30px 45px 30px;
	}
	.widget-testimonial {
		padding: 90px 40px 100px 40px;
		padding: 90px 40px 100px 40px;
	}
	.share-list .url {
		width: 90px;
		padding: 9px 5px;
	}
	.share-list .icon {
		font-size: 20px;
		line-height: 1em;
		padding: 0 2px;
	}
	.share-list .number {
		padding: 0 5px;
	}
	.page-penny-stocking .tim-penny-stocking-navigation .next-div p {
		min-width: 140px;
	}
}
@media (max-width: 1279px) {
	.comment-form.two-columns form {
		margin-right: -10px;
		margin-left: -10px;
	}
	.comment-form.two-columns [class*="col-"] {
		padding: 0 10px;
	}
	.form.two-columns form {
		margin-right: -10px;
		margin-left: -10px;
	}
	.form.two-columns [class*="col-"] {
		padding: 0 10px;
	}
}
@media (max-width: 991px) {
	.form-lt {
		padding-top: 100px;
		padding-bottom: 60px;
	}
}
@media screen and (max-width: 800px) and (orientation: landscape) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}
@media only screen and (max-width: 960px) {
	.main-article {
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
	}
	.entry-header .title {
		font-size: 30px;
		font-size: 30px;
	}
	.comment-heading .entry-subtitle {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
	}
	.comment-list {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-bottom: 50px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		margin-bottom: 50px;
	}
	.comment-respond {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		padding-bottom: 50px;
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
		padding-bottom: 50px;
	}
	#shares {
		display: none !important;
	}
}
@media only screen and (max-width: 767px) {
	.entry-content {
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
	}
	.comment-author {
		font-size: 18px;
		line-height: 1.721428571em;
		font-size: 18px;
		line-height: 1.721428571em;
	}
	.comment-list .comment-reply-title {
		font-size: 18px;
		line-height: 1.721428571em;
		font-size: 18px;
		line-height: 1.721428571em;
	}
	.children .comment-respond {
		margin-left: 17px;
		margin-left: 17px;
	}
	.children .comment-respond .p-email {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.children .comment-respond .p-name {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.children .comment-body {
		padding-left: 15px;
		padding-left: 15px;
	}
	.children .comment-author:after {
		left: -18px;
		left: -18px;
	}
	.comment .children {
		margin-left: 15px;
		margin-left: 15px;
	}
}
@media only screen and (max-width: 479px) {
	.entry-header .title {
		font-size: 27px;
		font-size: 27px;
	}
	.entry-content h2 {
		font-size: 20px;
		font-size: 20px;
	}
	.entry-content h3 {
		font-size: 20px;
		font-size: 20px;
	}
	.comment-respond .p-email {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.comment-respond .p-name {
		width: 100%;
		float: none;
		width: 100%;
		float: none;
	}
	.np-domain-check {
		padding: 20px 10px;
		padding: 20px 10px;
	}
	.np-domain-check .cols {
		display: block;
		margin-bottom: 10px;
		display: block;
		margin-bottom: 10px;
	}
	.np-domain-check .cols .field-p {
		display: block;
		margin-bottom: 10px;
		display: block;
		margin-bottom: 10px;
	}
	.np-domain-check .cols .field-p:last-child {
		margin-bottom: 0;
		margin-bottom: 0;
	}
	.np-domain-check .cols .field-p-name {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .field-p-submit {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .field-p-tld {
		width: 100%;
		width: 100%;
	}
	.np-domain-check .cols .btn {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.np-domain-check .field-p {
		padding: 0;
		padding: 0;
	}
	.np-domain-check .info-list {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		-moz-column-gap: 0;
		column-gap: 0;
		-webkit-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		column-gap: 0;
	}
	.np-keyword-check .cols {
		margin-bottom: 0;
		margin-bottom: 0;
	}
}
@media (min-width: 960px) {
	.main-aside {
		float: right;
		width: 33.05085%;
		float: right;
		width: 33.05085%;
	}
}
#main {
	overflow: hidden;
	padding-top: 40px;
	padding-bottom: 25px;
}
.main-article {
	float: left;
	width: 59.32203%;
}
.tim-thumbnail-outer img {
	width: 100%;
	height: auto;
}
.entry-header {
	padding: 20px 0;
}
.entry-header h1 {
	font-size: 27px;
	line-height: 1.3;
	font-weight: 400;
	padding: 30px 0 0;
	margin-bottom: 25px;
	position: relative;
	margin-top: 35px;
}
.entry-header h1::after {
	display: block;
	content: "";
	width: 60px;
	height: 2px;
	background: #3fa9f5;
	position: absolute;
	top: 0;
	left: 0;
}
.entry-header .breadcrumbs {
	font-weight: 300;
	color: #7b8086;
	margin-bottom: 3.58333em;
}
.entry-header .breadcrumbs a {
	color: inherit;
}
.entry-header .entry-meta {
	font-size: 14px;
	color: #aeb0b3;
	padding-bottom: 20px;
}
.entry-header .entry-meta a {
	color: #7b8086;
}
.entry-content .more-link {
	white-space: nowrap;
}
.entry-content h1 {
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 40px;
}
.entry-content ol + h2,
.entry-content ol + h3,
.entry-content p + h1,
.entry-content p + h2,
.entry-content p + h3,
.entry-content ul + h2,
.entry-content ul + h3 {
	padding-top: 1.666666666667em;
}
.entry-content strong {
	font-weight: 700;
}
.entry-content em {
	font-style: italic;
}
.entry-content blockquote {
	font-style: italic;
	margin-bottom: 1.666666666667em;
	position: relative;
	padding: 45px 35px;
}
.entry-content blockquote:last-child {
	margin-bottom: 0;
}
.entry-content blockquote:after,
.entry-content blockquote:before {
	content: "";
	display: block;
	width: 87px;
	height: 76px;
	background: center no-repeat;
	position: absolute;
}
.entry-content blockquote:before {
	top: 0;
	left: 0;
	background-image: url(../assets/images/bg-blockquote-blog-open.png);
}
.entry-content blockquote:after {
	right: 0;
	bottom: 0;
	background-image: url(../assets/images/bg-blockquote-blog-close.png);
}
.entry-content ol,
.entry-content p,
.entry-content ul {
	margin-bottom: 1.666666666667em;
}
.entry-content li {
	margin-bottom: 0.8333333333335em;
}
.entry-content h1:last-child .entry-content h2:last-child,
.entry-content h3:last-child,
.entry-content li:last-child,
.entry-content ol:last-child,
.entry-content p:last-child,
.entry-content ul:last-child {
	margin-bottom: 0;
}
.entry-content ol,
.entry-content ul {
	margin-left: 1.388888888889em;
}
.entry-content ul li {
	list-style: none;
	position: relative;
	padding: 0 0 0 33px;
}
.entry-content ul li:before {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	background: #3fa9f5;
	position: absolute;
	top: 0.8333333333335em;
	left: 0;
	margin-top: -4px;
}
.entry-content ul li .alignleft,
.entry-content ul li .alignright,
.entry-content ul li img {
	margin-bottom: 5px;
}
.entry-content ol li {
	list-style: decimal;
}
.entry-content li ol,
.entry-content li ul {
	margin-top: 0.8333333333335em;
}
.entry-content .wp-caption {
	margin-bottom: 1.666666666667em;
}
.entry-content .wp-caption img {
	display: block;
	margin-bottom: 0.8333333333335em;
}
.entry-content .wp-caption-text {
	font-style: italic;
}
.entry-content img.aligncenter {
	margin: 0 auto;
	display: block;
}
.entry-content img.alignone {
	display: block;
}
.entry-content div.aligncenter {
	margin: 0 auto;
}
.entry-content div.aligncenter img,
.entry-content div.alignone img {
	display: block;
}
.entry-content .alignleft {
	float: left;
	margin: 0.4166666666668em 1.666666666667em 1.666666666667em 0;
}
.main-article .entry-content .align-sc {
	margin-bottom: 1.3em;
}
.entry-content .alignright {
	float: right;
	margin: 0.4166666666668em 0 1.666666666667em 1.666666666667em;
}
.entry-content iframe {
	max-width: 100%;
}
.post-thumbnail {
	background: #e9e9e9;
	margin-bottom: 30px;
}
.post-thumbnail img {
	width: 100%;
	height: auto;
}
.entry-footer {
	overflow: hidden;
	font-size: 17px;
	font-size: 0.85rem;
	line-height: 1.6em;
	font-weight: 600;
	font-style: italic;
}
.entry-footer p {
	padding-bottom: 0 !important;
}
.entry-footer .comments {
	float: left;
}
.entry-footer a {
	color: #3f4a4d;
	text-decoration: underline;
}
.entry-footer a:hover {
	text-decoration: none;
}
.entry-footer .tweet {
	float: right;
}
.entry-footer .tweet iframe {
	margin: 3px 0 0 0;
}
@media only screen and (max-width: 960px) {
	.main-article {
		float: none;
		width: auto;
		max-width: 700px;
		margin: 0 auto;
	}
	.entry-header .title {
		font-size: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.entry-content {
		font-size: 16px;
		line-height: 1.75em;
		letter-spacing: 0;
	}
}
@media only screen and (max-width: 479px) {
	.entry-header .title {
		font-size: 27px;
	}
	.entry-content h2,
	.entry-content h3 {
		font-size: 20px;
	}
}
.np-testimonial {
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.np-testimonial .border {
	padding-top: 17px;
}
.np-testimonial .border:after {
	top: 0;
	left: 50%;
	margin-left: -40px;
}
.np-testimonial-text {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
	font-size: 18px;
	line-height: 1.66667;
	margin-bottom: 1.125em;
	letter-spacing: 0.025em;
	quotes: "“" "”" "‘" "’";
}
.np-testimonial-text:before {
	content: open-quote;
}
.np-testimonial-text:after {
	content: close-quote;
}
.np-testimonial-title {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
	font-size: 16px;
	line-height: 1.66667;
	font-weight: 600;
	padding-top: 1.25em;
}
.np-testimonial-img {
	display: inline-block;
}
.main-aside {
	max-width: 700px;
	margin: 0 auto;
}
@media (min-width: 960px) {
	.main-aside {
		float: right;
		width: 33.05085%;
	}
}
.widget {
	background: #f6fbff;
	margin-bottom: 60px;
	margin-top: 40px;
}
.widget:last-child {
	margin-bottom: 0;
}
.widget input::-webkit-input-placeholder {
	color: inherit;
}
.widget input::-moz-placeholder {
	color: inherit;
}
.widget input::-ms-input-placeholder {
	color: inherit;
}
.widget input::placeholder {
	color: inherit;
}
.widget input:focus::-webkit-input-placeholder {
	color: #e4e8ed;
}
.widget input:focus::-moz-placeholder {
	color: #e4e8ed;
}
.widget input:focus::-ms-input-placeholder {
	color: #e4e8ed;
}
.widget input:focus::placeholder {
	color: #e4e8ed;
}
.widget .btn-wrap {
	text-align: center;
}
.widget .btn {
	padding: 23px 20px;
	min-width: 225px;
	max-width: 100%;
	background-color: #ff4500;
	border-color: #ff4500;
}
.widget .btn:hover {
	background-color: #fff;
	color: #ff4500;
}
.widget-default {
	text-align: center;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-default {
		padding: 35px 40px 60px 40px;
	}
}
.widget-default .title {
	padding-top: 0.58333em;
	margin-bottom: 0.75em;
	font-size: 26px;
	line-height: 1.6;
}
.widget-default .title.border {
	padding-bottom: 20px;
	margin-bottom: 25px;
}
.widget-default .title.border:after {
	width: 40px;
	margin-left: -20px;
	left: 50%;
	height: 2px;
}
.widget-default p {
	margin-bottom: 15px;
}
@media (min-width: 1200px) {
	.widget-default .tim-widget-description,
	.widget-default .title {
		margin-right: auto;
		margin-left: auto;
	}
}
.widget-default .description {
	margin-bottom: 30px;
}
.widget-default .tim-sidebar-form {
	padding-top: 20px;
}
.widget-default .tim-sidebar-form input[type="text"] {
	width: 225px;
	max-width: 100%;
}
.widget-default .tim-sidebar-form .form-submit {
	margin: 40px 0;
}
.widget-about {
	border: solid #e9e9e9 1px;
	font-size: 14px;
	line-height: 1.71429;
	letter-spacing: 0.025em;
}
.widget-about .img-wrap {
	border-bottom: solid #e9e9e9 1px;
	padding-top: 10px;
}
.widget-about .img {
	margin: 0 auto;
}
.widget-about .text {
	padding: 20px 10px;
	color: #707378;
}
@media (min-width: 480px) {
	.widget-about .text {
		padding: 35px 40px 30px 40px;
	}
}
.widget-about p {
	margin-bottom: 1.71429em;
}
.widget-about p:last-child {
	margin-bottom: 0;
}
.widget-about .title {
	padding-top: 0.63636em;
	margin-bottom: 0.63636em;
	font-weight: 700;
	color: #292b2c;
}
.widget-about .title:after {
	width: 40px;
	height: 2px;
	top: 0;
}
.widget-search {
	position: relative;
}
.widget-search .form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	border: solid #e4e8ed 1px;
	padding: 0 10px;
	border-radius: 2px;
}
@media (min-width: 480px) {
	.widget-search .form {
		padding: 0 35px 0 40px;
	}
}
.widget-search .submit,
.widget-search .text {
	padding: 0;
}
.widget-search .text {
	padding-right: 10px;
}
@media (min-width: 480px) {
	.widget-search .text {
		padding-right: 35px;
	}
}
.widget-search .submit {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 28px;
	height: 28px;
	background: url(../assets/images/bg-search.svg) right center no-repeat;
	cursor: pointer;
}
.widget-help {
	text-align: center;
}
.widget-help .title-wrap {
	background: #3fa9f5;
	color: #fff;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-help .title-wrap {
		padding: 50px 30px 45px 30px;
	}
}
.widget-help .title {
	padding-top: 0.61111em;
	font-weight: 600;
}
.widget-help .border:after {
	top: 0;
	left: 50%;
	height: 2px;
	background: #fff;
	width: 40px;
	margin-left: -20px;
}
.widget-testimonial {
	background: #3fa9f5;
	color: #fff;
	padding: 20px 10px;
}
@media (min-width: 480px) {
	.widget-testimonial {
		padding: 90px 40px 100px 40px;
	}
}
.widget-testimonial .border:after {
	background: #fff;
}
@media (min-width: 768px) {
	.widget-testimonial .np-testimonial-text {
		font-size: 20px;
		line-height: 2.2;
	}
}
.widget-testimonial .np-testimonial-title {
	color: #e4e8ed;
	margin-bottom: 2em;
}
.widget-testimonial .btn-wrap {
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
}
.widget-optin {
	text-align: center;
	border: solid #e9e9e9 1px;
	padding: 35px 40px;
	font-size: 14px;
	line-height: 1.714285714286em;
	letter-spacing: 0.25px;
}
.widget-optin .title {
	font-size: 28px;
	line-height: 1.285714285714em;
	font-weight: 600;
	margin: 0 auto 0.5em auto;
	max-width: 320px;
}
.widget-optin .np-form {
	margin-bottom: 0;
}
.widget-optin ul {
	text-align: left;
	padding-top: 1em;
}
.widget-optin li {
	list-style: none;
	position: relative;
	padding: 0 0 0 20px;
	margin: 0 0 12px 0;
	color: #e9e9e9;
}
.widget-optin li:last-child {
	margin-bottom: 0;
}
.widget-optin li:before {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	background: #e9e9e9;
	position: absolute;
	top: 0.875em;
	left: 0;
	margin-top: -3px;
}
@media (min-width: 768px) {
	.widget-best-content .btn-group-lg {
		margin: 0 -40px;
	}
}
.widget-best-content .btn-group-lg .btn {
	min-width: 107px;
}
.widget-best-content .tab-content-area {
	width: 100%;
}
.widget-best-content .tab-content-area li {
	width: 100%;
	clear: both;
	overflow: hidden;
	padding: 15px 0;
	text-align: left;
}
.widget-best-content .tab-content-area li img {
	float: left;
	margin: 0 15px 10px 0;
	font-size: 3px;
	line-height: 1;
}
.widget-best-content .tab-content-area li a {
	color: #292b2c;
}
.tim-widget-altucher {
	padding: 10px 10px 0 10px;
}
.np-domain-check {
	padding: 30px;
	background: #f5f5f5;
	border: solid #e9e9e9 1px;
	position: relative;
	margin-bottom: 1.666666666667em;
}
.np-domain-check .title {
	margin-bottom: 1em;
}
.np-domain-check .cols {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.np-domain-check .cols .field-p-name {
	padding-left: 0;
	width: 65%;
}
.np-domain-check .cols .field-p-submit,
.np-domain-check .cols .field-p-tld {
	padding-right: 0;
	width: 35%;
}
.np-domain-check .cols .btn {
	padding-top: 19px;
	padding-bottom: 19px;
	width: 100%;
	display: block;
}
.np-domain-check .field-p {
	display: table-cell;
	vertical-align: middle;
	padding: 0 10px;
	width: 50%;
}
.np-domain-check .field-p-submit {
	margin-bottom: 0;
}
.np-domain-check .info-list {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 20px;
	-moz-column-gap: 20px;
	column-gap: 20px;
}
.np-domain-check .info-list a {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
}
.np-domain-check .submit {
	width: 100%;
	padding: 10px 15px;
	font-size: 16px;
	line-height: 1.75em;
	margin-bottom: 0;
}
.np-domain-check .info {
	padding-top: 1.666666666667em;
	display: none;
}
.np-keyword-check .cols {
	margin-bottom: 0;
}
@media only screen and (max-width: 479px) {
	.np-domain-check {
		padding: 20px 10px;
	}
	.np-domain-check .cols,
	.np-domain-check .cols .field-p {
		display: block;
		margin-bottom: 10px;
	}
	.np-keyword-check .cols {
		margin-bottom: 0;
	}
	.np-domain-check .cols .field-p-name,
	.np-domain-check .cols .field-p-submit,
	.np-domain-check .cols .field-p-tld {
		width: 100%;
	}
	.np-domain-check .cols .btn {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.np-domain-check .cols .field-p:last-child {
		margin-bottom: 0;
	}
	.np-domain-check .field-p {
		padding: 0;
	}
	.np-domain-check .info-list {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0;
		-moz-column-gap: 0;
		column-gap: 0;
	}
}
.entry-share {
	padding: 25px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border: solid #e9e9e9 1px;
	border-left: 0;
	border-right: 0;
	margin: 60px 0 32px 0;
}
.entry-share .title {
	display: none;
	padding: 20px 0;
}
@media (min-width: 768px) {
	.entry-share .title {
		display: block;
		font-weight: 400;
	}
}
.share-list {
	font-size: 14px;
	line-height: 1.714285714286em;
	letter-spacing: 0.025em;
	color: #fff;
}
.entry-share .share-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.share-list .share {
	border-radius: 2px;
	margin: 10px 0 0 0;
}
.share-list .share:first-child {
	margin-top: 0;
}
.entry-share .share-list .share {
	margin: 0 0 0 10px;
}
.entry-share .share-list .share:first-child {
	margin-left: 0;
}
@media (min-width: 768px) {
	.entry-share .share-list .share:first-child {
		margin-left: 35px;
	}
}
.share-list .url {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 60px;
	padding: 9px 2px;
	color: inherit;
	text-decoration: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (min-width: 480px) {
	.share-list .url {
		width: 90px;
		padding: 9px 5px;
	}
}
.share-list.small .url {
	width: 60px;
	padding: 9px 2px;
}
.share-list .icon {
	font-size: 15px;
	line-height: 1em;
	padding: 0 5px;
}
@media (min-width: 480px) {
	.share-list .icon {
		font-size: 20px;
		line-height: 1em;
		padding: 0 2px;
	}
}
.share-list.small .icon {
	font-size: 15px;
	line-height: 1em;
	padding: 0 2px;
}
.share-list .number {
	padding: 0 2px;
}
@media (min-width: 480px) {
	.share-list .number {
		padding: 0 5px;
	}
}
.share-list.small .number {
	padding: 0 2px;
}
.share-list .facebook {
	background: #6788ce;
}
.share-list .twitter {
	background: #29c5f6;
}
.share-list .linkedin {
	background: #3a9bdc;
}
.share-list .googleplus {
	background: #e75c3c;
}
.tim-main-article {
	position: relative;
}
.tim-main-article #shares {
	display: none;
	position: absolute;
	right: -70px;
	top: 400px;
}
@media only screen and (max-width: 960px) {
	#shares {
		display: none !important;
	}
}
.comments-area {
	border-top: 1px solid #f5f5f5;
}
.tim-comment-heading {
	padding: 25px 0;
	margin-top: 40px;
}
.tim-comment-heading h5 {
	font-weight: 400;
	font-size: 22px;
}
.tim-comment-heading h5 span {
	color: #3fa9f5;
	font-weight: 700;
}
.tim-first-comment .comment-author {
	border-top: none;
	padding-top: 5px;
	margin-bottom: 0;
}
.comment-list {
	padding: 15px 0 27px 0;
	margin: 0;
	border-bottom: solid #e9e9e9 1px;
}
.comment-list .comment .comment .comment-body {
	letter-spacing: 0.025em;
	font-size: 14px;
	line-height: 1.71429;
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	border-left: solid #e9e9e9 1px;
	padding-left: 50px;
}
.comment-list .comment .comment em {
	font-style: italic;
}
.comment-list .comment .comment strong {
	font-weight: 700;
}
.children .comment-list .comment .comment {
	padding-left: 50px;
}
.comment-list .comment .comment blockquote,
.comment-list .comment .comment ol,
.comment-list .comment .comment p,
.comment-list .comment .comment ul {
	margin-bottom: 1.71429em;
}
.comment-list .comment .comment blockquote:last-child,
.comment-list .comment .comment ol:last-child,
.comment-list .comment .comment p:last-child,
.comment-list .comment .comment ul:last-child {
	margin-bottom: 0;
}
.comment-list .comment .reply-link {
	padding-top: 7px;
}
.comment-list .comment .btn {
	width: auto;
	min-width: 0;
	padding: 12px 30px;
}
.comment-list .children > .bypostauthor .comment-body {
	border-left-color: #3fa9f5;
}
.comment-author {
	border-top: solid #e9e9e9 1px;
	padding-top: 43px;
	margin-top: 27px;
	position: relative;
}
.comment-author img {
	float: left;
	margin: 0 15px 10px 0;
}
.comment-author time {
	font-weight: 400;
	display: block;
	font-size: 12px;
	line-height: 1.2;
	padding-bottom: 3px;
}
.comment-content {
	clear: both;
	padding-top: 10px;
}
.comment-content p {
	font-size: 14px;
	margin-bottom: 10px;
}
.comment-list > .comment:first-child > .comment-body > .comment-author {
	padding-top: 0;
	border-top: 0;
}
.children .comment-author {
	padding-top: 25px;
}
.children .comment-author:after {
	content: "";
	display: block;
	width: 5px;
	height: 36px;
	background: #fff;
	position: absolute;
	top: -2px;
	left: -52px;
}
.comment-author .comment-edit-link {
	font-size: 12px;
	line-height: 1.66667;
	font-weight: 300;
	color: #aeb0b3;
}
.comment-author {
	font-size: 22px;
	line-height: 1.664285714em;
	font-weight: 700;
	color: #292b2c;
	margin-bottom: 0.5em;
}
.comment-author a {
	color: #292b2c;
}
.comment .children {
	margin-left: 50px;
}
.comment-respond {
	padding: 80px 0 125px 0;
}
.comment-respond .field {
	width: 100%;
	color: #3fa9f5;
	font-size: 16px;
	line-height: 1.66667;
	font-weight: 400;
	letter-spacing: 0.025em;
	padding: 23px 25px;
	margin-bottom: 10px;
	border: solid #f3f4f5 1px;
}
.comment-respond .field::-webkit-input-placeholder {
	color: #292b2c;
	opacity: 1;
}
.comment-respond .field::-moz-placeholder {
	color: #292b2c;
	opacity: 1;
}
.comment-respond .field::-ms-input-placeholder {
	color: #292b2c;
	opacity: 1;
}
.comment-respond .field::placeholder {
	color: #292b2c;
	opacity: 1;
}
.comment-respond .field-comment {
	height: 135px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.comment-list .comment-respond {
	padding: 25px 0;
	max-width: none;
}
.children .comment-respond {
	margin-left: 51px;
}
.comment-respond .comment-reply-title {
	color: #3fa9f5;
	margin-bottom: 1.47222em;
}
.comment-list .comment-respond .comment-reply-title {
	font-size: 22px;
	line-height: 1.664285714em;
	font-weight: 700;
	color: inherit;
}
.comment-respond .comment-reply-title small {
	font-weight: 300;
}
.comment-list .comment-respond .comment-reply-title small:before {
	content: " | ";
}
.comment-respond .p-email,
.comment-respond .p-name {
	width: 50%;
	float: left;
}
.comment-respond .p-name {
	padding-right: 5px;
}
.comment-respond .p-email {
	padding-left: 5px;
}
.comment-respond .p-url {
	clear: both;
}
.comment-respond .form-submit {
	padding-top: 30px;
	text-align: right;
	clear: both;
}
.comment-respond .form-submit .btn {
	min-width: 0;
	padding: 15px 30px;
}
.comment-awaiting-moderation {
	font-weight: 600;
}
.ct_comment_info {
	display: none;
}
@media only screen and (max-width: 960px) {
	.comment-heading .entry-subtitle,
	.comment-list,
	.comment-respond {
		margin-right: auto;
		margin-left: auto;
		max-width: 700px;
	}
	.comment-list {
		margin-bottom: 50px;
	}
	.comment-respond {
		padding-bottom: 50px;
	}
}
@media only screen and (max-width: 767px) {
	.comment-author,
	.comment-list .comment-reply-title {
		font-size: 18px;
		line-height: 1.721428571em;
	}
	.children .comment-respond .p-email,
	.children .comment-respond .p-name {
		width: 100%;
		float: none;
	}
	.comment .children {
		margin-left: 15px;
	}
	.children .comment-respond {
		margin-left: 17px;
	}
	.children .comment-body {
		padding-left: 15px;
	}
	.children .comment-author:after {
		left: -18px;
	}
}
@media only screen and (max-width: 479px) {
	.comment-respond .p-email,
	.comment-respond .p-name {
		width: 100%;
		float: none;
	}
}
.slick-slider {
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	display: none !important;
	background: url(../assets/images/loading-blog.svg) no-repeat 50% 50%;
}
.slick-slider.slick-initialized {
	display: block !important;
	background: 0 0;
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: 0;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:after,
.slick-track:before {
	content: "";
	display: table;
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}
[dir="rtl"] .slick-slide {
	float: right;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow {
	background: 0 0;
	width: 62px;
	height: 62px;
	overflow: hidden;
	text-indent: -3000px;
	position: absolute;
	cursor: pointer;
	border: 1px solid #e9e9e9;
	background: #fff;
	z-index: 100;
}
.slick-arrow.slick-next {
	top: 30%;
	right: 0;
	background: url(../assets/images/small-right.svg) no-repeat 50% 50%;
}
.slick-arrow.slick-prev {
	left: 0;
	top: 30%;
	background: url(../assets/images/small-left.svg) no-repeat 50% 50%;
}
.tim-slider-navigation {
	position: relative;
	max-width: 100%;
	width: 1200px;
	margin: 20px auto 0 auto;
	padding: 0 10px;
	height: 32px;
	overflow: hidden;
}
@media (min-width: 768px) {
	.tim-slider-navigation:before {
		width: 100%;
		height: 1px;
		background: #e9e9e9;
		content: "";
		top: 15px;
		right: 100px;
		position: absolute;
		display: block;
	}
}
.tim-slider-navigation .slick-arrow {
	border: none;
	background: 0 0;
	width: 32px;
	height: 32px;
	overflow: hidden;
	text-indent: -3000px;
	position: absolute;
	cursor: pointer;
	left: auto;
}
@media (max-width: 767px) {
	.tim-slider-navigation .slick-arrow {
		width: 46%;
	}
}
.tim-slider-navigation .slick-arrow.slick-next {
	top: 0;
	right: 0;
	background: url(../assets/images/small-right.svg) no-repeat top left;
}
@media (min-width: 768px) {
	.tim-slider-navigation .slick-arrow.slick-next {
		background: url(../assets/images/small-right.svg) no-repeat top right;
	}
}
.tim-slider-navigation .slick-arrow.slick-prev {
	left: 0;
	top: 0;
	background: url(../assets/images/small-left.svg) no-repeat top right;
}
@media (min-width: 768px) {
	.tim-slider-navigation .slick-arrow.slick-prev {
		right: 50px;
		left: auto;
		background: url(../assets/images/small-left.svg) no-repeat;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}
.tim-challenge-intro-section {
	padding: 50px 0 80px 0;
}
@media (min-width: 768px) {
	.tim-challenge-intro-section {
		padding: 80px 0 70px 0;
	}
}
.tim-challenge-intro-section .tim-section-header {
	margin-bottom: 0;
	padding-bottom: 0;
}
.tim-challenge-intro-section .tim-section-header .border {
	margin-bottom: 55px;
}
@media (min-width: 768px) {
	.tim-challenge-intro-section .tim-section-header .border span {
		display: block;
	}
}
.tim-challenge-intro-section .tim-disclaimer {
	// margin-top: 45px;
	line-height: 2.5;
	text-align: center;
}

#infusion {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@media (max-width: 768px) {
	.tim-challenge-intro-section .tim-section-header .border span {
		margin-bottom: 24px;
	}
}
.tim-challenge-intro-section .tim-video-decorator .wistia_embed {
	-webkit-box-shadow: 0 29px 60px 0 rgba(54, 57, 73, 0.09);
	box-shadow: 0 29px 60px 0 rgba(54, 57, 73, 0.09);
}
.tim-section-intro-newsletter {
	padding: 70px 0 70px 0;
}
.tim-trading-as-seen-on {
	padding-top: 30px;
}
@media (min-width: 768px) {
	.tim-trading-as-seen-on {
		padding: 60px 0 80px 0;
	}
}
.tim-trading-as-seen-on .border {
	margin-bottom: 52px;
}
.tim-testimonials-slider-section {
	padding: 20px 0;
}
@media (min-width: 768px) {
	.tim-testimonials-slider-section {
		padding: 90px 0 20px 0;
	}
}
.tim-testimonials-slider-section h3.title {
	line-height: 1.5;
}
.tim-testimonials-slider-section h3.title.border {
	padding-bottom: 35px;
	margin-bottom: 0;
}
@media (max-width: 767px) {
	.tim-testimonials-slider-section h3.title span {
		display: block;
		font-size: 19px;
	}
	.tim-testimonials-slider-section h3.title span strong {
		font-weight: 400;
	}
	.tim-testimonials-slider-section h3.title span strong:before {
		display: none;
	}
}
.tim-testimonials-slider-section .tim-section-header {
	margin-bottom: 0;
}
.tim-section-hero {
	padding-bottom: 0;
	padding-top: 80px;
}
.tim-section-hero .tim-img-block {
	width: 70%;
	height: auto;
	margin: 0 auto;
}
@media (min-width: 1200px) {
	.tim-section-hero .tim-img-block {
		margin: -50px 0 0 -100px;
		width: 501px;
		height: 749px;
		max-width: none;
	}
}
@media (min-width: 768px) {
	.tim-section-hero .tim-box-column {
		padding: 60px 0 30px 50px;
	}
}
.tim-section-hero .tim-box-column .title {
	line-height: 1.5;
}
@media (min-width: 768px) {
	.tim-section-hero .tim-box-column .title.border:after {
		left: 0;
	}
}
@media (min-width: 768px) {
	.tim-section-hero .tim-box-column .title strong:before {
		bottom: 11px;
	}
}
.tim-section-hero .tim-box-column p {
	margin-bottom: 20px;
}
.tim-section-newsletter-second {
	padding-bottom: 70px;
}
.tim-students-section {
	padding-top: 50px;
}
@media (min-width: 768px) {
	.tim-students-section {
		padding-top: 100px;
	}
}
.tim-students-section .tim-image-outer {
	padding: 20px;
	background: #f6fbff;
}
.tim-students-section .tim-image-outer img {
	width: 100%;
	height: auto;
}
.tim-students-section .row {
	padding-bottom: 100px;
}
.tim-students-section h4 {
	font-size: 26px;
	color: #3fa9f5;
	font-weight: 600;
	margin: 30px 0 15px;
}
.tim-students-section .ttext {
	font-size: 15px;
}
@media (min-width: 768px) {
	.tim-students-section .ttext {
		font-size: 18px;
	}
}
.tim-millionaire-challenge .tim-video-decorator {
	margin-left: -15px;
	margin-right: -15px;
	max-width: none;
	padding-bottom: 0;
	-webkit-box-shadow: 0 29px 50px 0 rgba(54, 57, 73, 0.17);
	box-shadow: 0 29px 50px 0 rgba(54, 57, 73, 0.17);
}
.tim-millionaire-challenge .tim-video-decorator .tim-video-decorator-inside {
	width: 760px;
	padding: 3% 0 0 0;
	position: relative;
	max-width: 100%;
	margin-bottom: 64px;
}
@media (min-width: 768px) {
	.tim-millionaire-challenge .tim-video-decorator {
		margin-left: 0;
		margin-right: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		height: 440px;
		max-width: 645px;
		margin-left: auto;
		margin-right: auto;
	}
}
.tim-millionaire-challenge .title {
	font-size: 20px;
}
@media (min-width: 768px) {
	.tim-millionaire-challenge .title {
		font-size: 47px;
	}
}
.tim-millionaire-challenge .title.border {
	padding-bottom: 29px;
	margin-bottom: 25px;
}
@media (min-width: 768px) {
	.tim-millionaire-challenge .title.border {
		padding-bottom: 32px;
		margin-bottom: 35px;
	}
}
.tim-millionaire-challenge .title strong:before {
	bottom: 0;
	height: 10px;
}
@media (min-width: 768px) {
	.tim-millionaire-challenge .title strong:before {
		bottom: 10px;
	}
}
.tim-millionaire-challenge h3.title {
	font-size: 20px;
	line-height: 1.5;
}
@media (min-width: 768px) {
	.tim-millionaire-challenge h3.title {
		font-size: 35px;
	}
}
.tim-millionaire-challenge .tim-section-dark {
	padding: 60px 0 40px 0;
}
.tim-millionaire-challenge .tim-newsletter-box {
	padding-top: 10px;
}
.tim-millionaire-challenge .tim-newsletter-box .tim-newsletter-input {
	width: 320px;
	text-align: center;
	margin-bottom: 40px;
	font-size: 16px;
	padding-right: 0;
	max-width: 100%;
}
.tim-millionaire-challenge .tim-newsletter-box .btn {
	padding: 21px 27px;
	margin-bottom: 40px;
}
.w-form-done {
	display: none;
	padding: 20px;
	text-align: center;
	background-color: #ddd;
}
.w-form-fail {
	display: none;
	margin-top: 10px;
	padding: 10px;
	background-color: #ffdede;
}
.tim-testimonials-slider .slide {
	padding: 20px 15px;
}
.tim-testimonials-slider .slide .slide-challenge-box {
	background: #f6fbff;
	padding: 0 40px 10px 40px;
	font-size: 14px;
	text-align: left;
	height: 480px;
	position: relative;
	border-radius: 2px;
}
.tim-testimonials-slider .slide .slide-challenge-box .tim-slide-image {
	right: 0;
	overflow: hidden;
	margin: 0 -40px 25px -40px;
}
.tim-testimonials-slider .slide .slide-challenge-box .tim-slide-image img {
	width: 100% !important;
	height: auto !important;
}
.tim-testimonials-slider .slide .slide-challenge-box p {
	font-size: 14px;
	font-family: Fira Sans;
	text-align: left;
}
.tim-testimonials-slider .slick-slide {
	opacity: 0.5;
}
.tim-testimonials-slider .slick-slide.slick-active {
	opacity: 1;
}
@media (min-width: 768px) {
	.tim-testimonials-slider .slick-list {
		padding: 0 260px 0 0 !important;
	}
}
.tim-millionaire-challenge-step2 {
	padding-top: 150px;
	background: #fff;
	font-family: Fira Sans;
}
.tim-millionaire-challenge-step2 .tim-challenge-step-header h1 {
	font-size: 35px;
	font-weight: 600;
	padding: 20px 0;
	margin: 0;
}
.tim-millionaire-challenge-step2 .tim-step-timer {
	float: right;
	width: 130px;
	height: 60px;
	background: #f6fbff;
	line-height: 1.5;
	text-align: center;
	padding-top: 15px;
	font-size: 26px;
}
.tim-millionaire-challenge-step2 .tim-steps-show-section {
	padding: 75px 0 75px 0;
}
.tim-millionaire-challenge-step2 .tim-steps-show-section .tim-steps-show li {
	width: 50%;
	float: left;
	padding-bottom: 15px;
	position: relative;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
}
.tim-millionaire-challenge-step2
	.tim-steps-show-section
	.tim-steps-show
	li:before {
	content: "";
	display: block;
	width: 100%;
	height: 4px;
	background: #f5f5f5;
	position: absolute;
	bottom: 0;
	left: 0;
}
.tim-millionaire-challenge-step2
	.tim-steps-show-section
	.tim-steps-show
	li.tim-step-done:before {
	background: #3fa9f5;
	border-radius: 4px;
	height: 8px;
	bottom: -2px;
}
.tim-millionaire-challenge-step2 .tim-sidebar-box {
	background: #f6fbff;
	padding: 30px;
	margin-top: -40px;
}
.tim-millionaire-challenge-step2 .tim-sidebar-box h3 {
	font-weight: 600;
	font-size: 26px;
	line-height: 1.5;
}
.tim-millionaire-challenge-step2 .tim-sidebar-box p {
	line-height: 1.7;
}
.tim-millionaire-challenge-step2 .tim-section-step-2-form {
	padding-top: 0;
}
.tim-millionaire-challenge-step2 .tim-section-step-2-form .inner {
	padding-top: 40px;
}
.tim-millionaire-challenge-step2 .tim-opportunity-list {
	padding-top: 40px;
}
.tim-millionaire-challenge-step2 .tim-opportunity-list li {
	padding: 10px 0 13px 40px;
	background: url(../assets/images/check-circle-07-black.svg) no-repeat 0 15px;
	font-size: 16px;
}
.tim-millionaire-challenge-step2 .tim-security-box {
	border-top: 1px solid #f5f5f5;
	padding: 30px 0;
}
.tim-millionaire-challenge-step2 .tim-security-box img {
	float: left;
	margin: 0 20px 0 0;
}
.tim-millionaire-challenge-step2 .tim-security-box h4 {
	margin: 15px 0 0;
	font-size: 20px;
	padding-bottom: 0;
	font-weight: 600;
}
.tim-millionaire-challenge-step2 .tim-security-box p {
	font-size: 15px;
}
.tim-millionaire-challenge-step2 .tim-btn-form-area {
	text-align: center;
}
.tim-millionaire-challenge-step2 .tim-disclaimer {
	padding: 40px 20px 40px 0;
	line-height: 2;
}
.tim-millionaire-challenge-step2 .tim-checkbox-field-area {
	padding-top: 20px;
}
.tim-form-step-2 label {
	display: block;
	font-size: 16px;
	font-weight: 600;
}
.tim-form-step-2 input[type="email"],
.tim-form-step-2 input[type="phone"],
.tim-form-step-2 input[type="text"] {
	width: 100%;
}
.tim-form-step-2 .row {
	margin: 0 0 0 -30px;
	padding: 20px 0;
}
.tim-form-step-2 .row .col-md-6,
.tim-form-step-2 .row .col-sm-12 {
	padding: 0 30px;
}
.tim-form-step-2 textarea {
	width: 100%;
}
.tim-challenge-homework {
	background: #3fa9f5;
	padding: 45px 0;
	color: #fff;
}
.tim-challenge-homework .copyright {
	width: 100%;
	border-bottom: 2px solid #6ebff8;
	padding-bottom: 30px;
	margin-bottom: 40px;
	overflow: hidden;
	text-align: center;
}
.tim-challenge-homework .copyright p {
	font-size: 13px;
	padding-bottom: 20px;
}
@media (min-width: 768px) {
	.tim-challenge-homework .copyright p {
		float: left;
		font-size: 14px;
		text-align: left;
	}
}
.tim-challenge-homework .copyright .menu {
	padding-top: 20px;
	padding-bottom: 40px;
}
@media (min-width: 768px) {
	.tim-challenge-homework .copyright .menu {
		float: right;
		padding-top: 0;
		padding-bottom: 0;
	}
}
.tim-challenge-homework .copyright .menu li {
	clear: both;
	float: none;
	text-align: center;
}
@media (min-width: 768px) {
	.tim-challenge-homework .copyright .menu li {
		font-size: 15px;
		margin-left: 60px;
		clear: none;
		float: left;
		text-align: left;
	}
}
.tim-challenge-homework .copyright .menu a {
	color: #fff;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	padding: 10px 0;
	display: block;
}
@media (min-width: 768px) {
	.tim-challenge-homework .copyright .menu a {
		font-size: 13px;
		padding: 0;
		text-align: left;
	}
}

.tim-challenge-homework .notice {
	line-height: 1;
}

.tim-challenge-homework .notice p {
	display: initial;
	font-size: 12px;
}
.tim-share-counter {
	position: absolute;
	bottom: 2px;
	left: 2px;
	display: block;
	background: #fff;
	color: #000;
	border-radius: 100%;
	font-size: 10px;
	text-align: center;
	-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
	border: 1px solid #fff;
	min-width: 7px;
	padding: 0 3px;
	width: auto;
	height: 18px;
	line-height: 1.5;
}

@media (min-width: 768px) {
	.tim-video-decorator {
		height: 440px;
		width: 100%;
		max-width: 650px;
		margin-left: auto;
		margin-right: auto;
		&::before {
			background: #c6f1ff;
			content: "";
			display: block;
			top: 35px;
			left: 0;
			right: 0;
			bottom: 30px;
			position: absolute;
			z-index: -1;
			border-radius: 2px;
			height: 235px;
		}
	}
}

p.terms {
	font-size: 12px;
	font-family: "Rubik", sans-serif;
}

input[type="submit"] {
	margin-bottom: 0;
}
</style>
